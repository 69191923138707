import axios from './index';

/**
 * APIs
 */
// table list
export const incentivesListApi = (query: Record<string, any>) => {
    return axios<PageResType<IncentiveSeasonItem>>('/v1/incentives', {
        method: 'get',
        params: query,
    });
};

// soc
export const incentivesSocApi = () => {
    return axios<ResType<IncentiveSeasonSoc>>('/v1/incentives/soc', {
        method: 'get',
    });
};

// delete incentives by id
export const deleteIncentivesByIdApi = (id: string) => {
    return axios<ResType<IncentiveSeasonItem>>(`/v1/incentives/${id}`, {
        method: 'delete',
    });
};

// create incentives
export const createIncentivesApi = (data: IncentiveSeasonCreateItem) => {
    return axios<ResType<IncentiveSeasonItem>>('/v1/incentives', {
        method: 'post',
        data,
    });
};

// update incentives
export const updateIncentivesApi = (id: string, data: IncentiveSeasonCreateItem) => {
    return axios<ResType<IncentiveSeasonItem>>(`/v1/incentives/${id}`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

export type IncentiveSeasonTarget = {
    user: number;
    token_amount: number;
    soc?: number;
};

// incentives item
export type IncentiveSeasonItem = {
    id?: string;
    created_at: string;
    deleted_at?: string;
    updated_at?: string;
    target: IncentiveSeasonTarget[];
    start_at: string;
    end_at: string;
    status: string;
    operated_by: string;
    token_id: string;
};

// incentives create item
export type IncentiveSeasonCreateItem = {
    target: IncentiveSeasonTarget[];
    start_at?: string;
    token_id?: string;
    end_at: string;
    updated_at?: string;
};

// 获取激励计划数据列表
export type IncentiveSeasonListParams = {
    order_by?: string;
    order_sort?: 'asc' | 'desc';
    page?: number | string;
    size?: number | string;
    updated_end?: string;
    updated_start?: string;
};

export type IncentiveSeasonSoc = {
    soc_amount: number;
};
