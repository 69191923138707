export default {
    ms: {
        b: {
            refund: '退款',
        },
        f: {
            orderLimit: '订单数量上限',
            inventory: '库存',
            maxInfo: '每个用户的下单数量上限',
            orderLimitInfo: '每笔订单可以购买的数量上限',
        },
        t: {
            c: {
                orderId: '订单ID',
                code: '邀请码',
                parentUid: '邀请人UID',
                specification: '规格',
                unitPrice: '单价',
                quality: '数量',
                topPrice: '商品总价',
                shippingFee: '运费',
                paidAmount: '实付款',
                countryRegion: '国家/地区',
                address: '收货地址',
                postalCode: '邮编',
                paymentStatus: '付款状态',
                orderStatus: '订单状态',
                recipient: '收货人',
                paidAt: '付款时间',
                // refund
                refundId: '退款ID',
                refundAmount: '实退款',
                refundStatus: '退款状态',
                refundRequestedAt: '申请时间',
                refundedAt: '退款时间',
                // Inventory
                availableInventory: '可用库存',
                adjustedInventory: '设置库存',
                // order config
                purchaseLimit: '购买数量上限',
            },
        },
        s: {
            paid: '已支付',
            refunding: '退款中',
            refunded: '已退款',
        },
    },
};
