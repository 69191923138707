import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Select, Space } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { changeLivesRecommendations } from '@/services/live';

/**
 * Constants
 */
const liveStatusMap = {
    video: <FormattedMessage id="t.c.video" />,
    audio: <FormattedMessage id="cl.s.audio" />,
};

const liveOptions = Object.entries(liveStatusMap).map((v) => {
    return {
        label: v[1],
        value: v[0],
    };
});

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';

type Props = {
    open: boolean;
    closeCallback: (reload?: boolean) => void;
};

const ComponentAddDrawer: React.FC<Props> = ({ open, closeCallback }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeCallback()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await changeLivesRecommendations(formData.id, {
                                            is_recommended: true,
                                        });
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeCallback(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="b.add" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            }
        },
        onClose: () => {
            closeCallback();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="type"
                    label={intl.formatMessage({ id: 't.c.type' })}
                    rules={[{ required: true }]}
                    initialValue={'audio'}
                >
                    <Select
                        allowClear={true}
                        showSearch={true}
                        optionFilterProp="label"
                        options={liveOptions}
                        placeholder={<FormattedMessage id="cl.t.c.liveStatus" />}
                        disabled
                    />
                </Form.Item>
                <Form.Item name="id" label={intl.formatMessage({ id: 'cl.t.c.homeId' })} rules={[{ required: true }]}>
                    <Input showCount maxLength={50} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentAddDrawer;
