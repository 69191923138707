export default {
    /**
     * Button
     */
    b: {
        add: '添加',
        approve: '通过',
        assign: '分配',
        bulkDelete: '批量删除',
        cancel: '取消',
        confirm: '确定',
        create: '创建',
        delete: '删除',
        details: '详情',
        edit: '编辑',
        export: '导出',
        import: '导入',
        download: '下载',
        login: '登录',
        logout: '登出',
        reject: '驳回',
        remark: '备注',
        revoke: '撤销',
        unbound: '释放',
        reset: '重置',
        save: '保存',
        search: '查询',
        transfer: '转移',
        upload: '上传',
        config: '配置',
    },
    /**
     * Common
     */
    c: {
        areyousure: '你确定吗？',
        areyousureBulk: '你确定要执行此批量操作么？',
        checkGroupId: '群组 ID 验证失败',
        checkPostId: '帖子 ID 验证失败',
        checkUserId: '用户 ID 验证失败',
        developing: '开发中',
        welcome: '欢迎！',
        pleaseInput: '请输入{key}',
        password: '密码',
        operationCompleted: '操作成功',
        importSuccess: '导入成功。共导入{total}条数据',
        importError: '导入失败。数据不符合格式要求',
        importPartSuccess: '{total}条数据导入成功，{failCount}条数据导入失败',
        notFound: '{value}不存在',
        alreadyExists: '{value}已存在',
        /**
         * Change Password Modal
         */
        cpm: {
            changePassword: '修改密码',
            oldPassword: '旧密码',
            newPassword: '新密码',
            sureNewPassword: '确认新密码',
            inputPasswordNotMatch: '新密码与确认密码不一致',
        },
    },
    /**
     * Table
     */
    t: {
        /**
         * Columns
         */
        c: {
            account: '账号',
            action: '操作',
            age: '年龄',
            appliedAt: '申请时间',
            approvedAt: 'Approved At',
            avatar: '头像',
            bio: '个人简介',
            city: '城市',
            country: '国家',
            countryRegion: '国家/地区',
            createdAt: '创建时间',
            createdBy: '创建人',
            date: '日期',
            dateRange: '日期',
            email: '邮箱',
            fullName: '姓名',
            gender: '性别',
            group: 'Group',
            link: '链接',
            nickname: '昵称',
            operatedAt: '操作时间',
            operatedBy: '操作人',
            detail: '详情',
            operationsUid: '运营 UID',
            owner: '归属人',
            occupation: '职业',
            role: '角色',
            sort: '排序',
            status: '状态',
            type: '类型',
            updatedAt: '更新时间',
            url: '网址',
            user: '用户',
            username: '用户名',
            video: '视频',
            registerDate: '报名时间',
            male: '男',
            female: '女',
        },
    },
    /**
     * Message
     */
    m: {
        exportTips: '导出中，稍后请至“系统管理-下载管理”查看',
    },
    country: {
        Malaysia: '马来西亚',
        Indonesia: '印度尼西亚',
        SriLanka: '斯里兰卡',
        India: '印度',
        Pakistan: '巴基斯坦',
        Vietnam: '越南',
        Philippines: '菲律宾',
        Bangladesh: '孟加拉',
        Nigeria: '尼日利亚',
        Egypt: '埃及',
    },
};
