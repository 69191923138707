export default {
    ms: {
        b: {
            refund: 'Refund',
        },
        f: {
            orderLimit: 'Order Limit',
            inventory: 'Inventory',
            maxInfo: 'Maximum Orders per User',
            orderLimitInfo: 'Maximum Purchase per Order',
        },
        t: {
            c: {
                orderId: 'Order ID',
                code: 'Code',
                parentUid: 'Parent UID',
                specification: 'Specification',
                unitPrice: 'Unit Price',
                quality: 'Quality',
                topPrice: 'Top Price',
                shippingFee: 'Shipping Fee',
                paidAmount: 'Paid Amount',
                countryRegion: 'Country/Region',
                address: 'Address',
                postalCode: 'Postal Code',
                paymentStatus: 'Payment Status',
                orderStatus: 'Status',
                recipient: 'Recipient',
                paidAt: 'Paid At',
                // refund
                refundId: 'Refund ID',
                refundAmount: 'Refund Amount',
                refundStatus: 'Refund Status',
                refundRequestedAt: 'Refund Requested At',
                refundedAt: 'Refunded At',
                // Inventory
                availableInventory: 'Available Inventory',
                adjustedInventory: 'Adjusted Inventory',
                // order config
                purchaseLimit: 'Purchase Limit',
            },
        },
        s: {
            paid: 'Paid',
            refunding: 'Refunding',
            refunded: 'Refunded',
        },
    },
};
