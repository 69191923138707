import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Flex, Form, Input, PaginationProps, Segmented, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import {
    exportRegistrantInformation,
    gRegistrantInformation,
    RegistrantInformationParams,
    RegistrantInformationResult,
    RegistrantInformationType,
} from '@/services/registrant-information';

/**
 * Components
 */
import Permission from '@/components/permission';
import SearchUser from '@/components/search-user';

/**
 * Constants
 */

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { PresetQuestionResult } from '@/services/preset-question';
import { FormProps } from 'antd/lib';
import { isEmpty, omitBy } from 'lodash';
import dayjs from 'dayjs';

type SegmentedType = 'Global' | 'Web3';

type State = {
    dataSource: RegistrantInformationResult[];
    entity: PresetQuestionResult | null;
    openDrawer: boolean;
    pageType: SegmentedType;
};

const PageDebateRegistrantInfomation: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    const tableRef = useRef<ActionType>();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
        pageType: 'Global',
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<RegistrantInformationParams>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading: loadingApi,
        refresh: refreshPresetQuestion,
        run: runApi,
    } = useRequest(gRegistrantInformation, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setPagination({
                total: data.meta.total,
            });
            setState({
                dataSource: data.list,
            });
        },
    });

    // 导出
    const { loading: loadingExportApi, run: runExportApi } = useRequest(exportRegistrantInformation, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            message.success(intl.formatMessage({ id: 'm.exportTips' }));
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<RegistrantInformationResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'name',
                width: 200,
                title: <FormattedMessage id="t.c.user" />,
                render: (_, entity: any) => {
                    const { username = '-', nickname = '-' } = entity;

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{username}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '_name',
                title: <FormattedMessage id="t.c.fullName" />,
                render: (_, entity) => {
                    return entity.info?.first_name + ' ' + entity.info?.last_name;
                },
            },
            {
                dataIndex: ['info', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                copyable: true,
                width: 200,
            },
            {
                dataIndex: ['info', 'phone'],
                ellipsis: true,
                width: 200,
                title: <FormattedMessage id="p.uu.t.c.mobile" />,
                render: (_, entity) => {
                    if (!entity.info?.phone) {
                        return '-';
                    }
                    return `+${entity.info?.area_code} ${entity.info?.phone}`;
                },
            },
            {
                dataIndex: ['info', 'country'],
                title: <FormattedMessage id="t.c.countryRegion" />,
                width: 200,
            },
            {
                dataIndex: ['info', 'city'],
                title: <FormattedMessage id="t.c.city" />,
                width: 120,
            },
            {
                dataIndex: ['info', 'school'],
                title: <FormattedMessage id="p.uu.t.c.school" />,
                width: 200,
            },
            {
                dataIndex: ['info', 'gender'],
                title: <FormattedMessage id="t.c.gender" />,
                valueEnum: {
                    male: <FormattedMessage id="t.c.male" />,
                    female: <FormattedMessage id="t.c.female" />,
                },
                width: 120,
            },
            {
                dataIndex: ['info', 'birthday'],
                title: <FormattedMessage id="p.uu.t.c.birthday" />,
                valueType: 'date',
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.registerDate" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingApi,
        options: {
            reload: refreshPresetQuestion,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1810,
            y: `calc(100vh - 352px)`,
        },
        search: false,
        toolBarRender: () => {
            return [
                <Segmented<SegmentedType>
                    options={['Global', 'Web3']}
                    value={state.pageType}
                    onChange={(value) => {
                        setState({ pageType: value });
                        setPagination({ current: 1 });
                    }}
                    key="group"
                />,
            ];
        },
    };

    /**
     * FormProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            const toServerParams: RegistrantInformationParams = {
                uid: values.user_id,
                nickname: values.nickname,
                name: values.username,
                email: values.email,
            };
            if (values.fe_register_date && values.fe_register_date.length === 2) {
                toServerParams.register_start = values.fe_register_date[0].startOf('day').format();
                toServerParams.register_end = values.fe_register_date[1].endOf('day').format();
            }

            values.fe_register_date = undefined;

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(toServerParams, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runApi(state.pageType === 'Global' ? RegistrantInformationType.Global : RegistrantInformationType.Web3, {
            page: pagination.current as any,
            limit: pagination.pageSize as any,
            ...omitBy(payload, isEmpty),
        });
    }, [payload, pagination.current, pagination.pageSize, state.pageType]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="email" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 't.c.email' })} allowClear />
                        </Form.Item>

                        <Form.Item
                            label={intl.formatMessage({ id: 't.c.registerDate' })}
                            name="fe_register_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker
                                allowClear={true}
                                disabledDate={(current) => {
                                    // 禁止选择未来的日期
                                    return current && current > dayjs().endOf('day');
                                }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Debate-Championship_Registrant-Information_Export">
                            <Button
                                loading={loadingExportApi}
                                onClick={async () => {
                                    runExportApi(
                                        state.pageType === 'Global'
                                            ? RegistrantInformationType.Global
                                            : RegistrantInformationType.Web3,
                                        {
                                            ...omitBy(payload, isEmpty),
                                        },
                                    );
                                }}
                            >
                                <FormattedMessage id="b.export" />
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageDebateRegistrantInfomation;
