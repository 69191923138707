import axios from './index';
// import localAxios from 'axios';

// const axios = localAxios.create({
//     baseURL: 'http://127.0.0.1:4523/m1/4939924-0-default',
// });

/**
 * APIs
 */

// 订单管理列表
export const gOrders = (params: OrdersParams) => {
    return axios<PageResType<OrderResult>>('/v1/phone-mall/orders', {
        method: 'get',
        params,
    });
};

// 订单管理导出
export const exportOrders = (params: OrdersParams) => {
    return axios<PageResType<null>>('/v1/phone-mall/orders/export', {
        method: 'get',
        params,
    });
};

// 订单退款
export const pRefund = (data: RefundParams) => {
    return axios<PageResType<null>>('/v1/phone-mall/refund/process', {
        method: 'post',
        data,
    });
};

// 退款管理列表
export const gRefunds = (params: RefundsParams) => {
    return axios<PageResType<RefundsResult>>('/v1/phone-mall/refunds', {
        method: 'get',
        params,
    });
};

// 库存管理列表
export const gStocks = (params: any) => {
    return axios<PageResType<StockResult>>('/v1/phone-mall/stocks', {
        method: 'get',
        params,
    });
};

// 库存管理列表
export const uStock = (data: UpdateStockData) => {
    return axios<PageResType<StockResult>>('/v1/phone-mall/stock/update', {
        method: 'post',
        data,
    });
};

// 下单管理列表
export const gOrderManager = () => {
    return axios<ResType<OrderManagerResult>>('/v1/phone-mall/orders-manager', {
        method: 'get',
    });
};

// 下单管理列表
export const pOrderLimit = (data: { max: number }) => {
    return axios<ResType<OrderManagerResult>>('/v1/phone-mall/user/max', {
        method: 'post',
        data,
    });
};

// 下单管理 设置国家购买限制
export const pCountryLimit = (data: CountryLimitParams) => {
    return axios<ResType<OrderManagerResult>>('/v1/phone-mall/country/max', {
        method: 'post',
        data,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

export type OrdersParams = {
    /**
     * 国家： 马来西亚、印度尼西亚、斯里兰卡、印度、巴基斯坦、越南、菲律宾、孟加拉、尼日利亚、埃及
     */
    country?: string;
    /**
     * 订单id
     */
    id?: string;
    /**
     * 邀请人
     */
    inviter_id?: string;
    /**
     * 每页记录数
     */
    limit?: string;
    /**
     * 订单状态：3| 已支付，6 | 已退款
     */
    order_status?: string;
    /**
     * 分页
     */
    page?: string;
    /**
     * 付款结束时间
     */
    payment_time_end?: string;
    /**
     * 付款开始时间
     */
    payment_time_start?: string;
    /**
     * 邀请码
     */
    referral_code?: string;
    /**
     * sku : 'SP001', 'SP002'
     */
    sku?: string;
    /**
     * 用户id
     */
    user_id?: string;

    fe_date?: any[];
};
export type OrderResult = {
    /**
     * 实付款
     */
    actual_payment: string;
    /**
     * 地址
     */
    address: string;
    /**
     * 国家
     */
    country: string;
    create_time: string;
    /**
     * 订单id
     */
    id: string;
    /**
     * 邀请人id
     */
    inviter_uid: null;
    /**
     * 用户名
     */
    name: string;
    /**
     * 订单状态  CREATED(1, "已创建，订单刚生成"),     PENDING_PAYMENT(2, "待支付，等待用户完成支付"),     PAID(3,
     * "已支付，用户支付成功"),     CANCELLED(4, "已取消，订单被取消"),     SHIPPED(5, "已发货，商品已发出"),
     * REFUNDED(6, "已退款，订单已退款");
     */
    order_status: number;
    /**
     * 付款状态 mapping 待定
     */
    payment_status: number;
    /**
     * 付款时间
     */
    payment_time: string;
    /**
     * 数量
     */
    quantity: number;
    /**
     * 收货人
     */
    receiver: string;
    /**
     * 邀请码
     */
    referral_code: string;
    /**
     * 运费
     */
    shipping_fee: string;
    /**
     * sku
     */
    sku: string;
    /**
     * 规格
     */
    spec: string;
    /**
     * 总价
     */
    total_price: string;
    /**
     * 单价
     */
    unit_price: string;
    update_time: string;
    /**
     * 用户id
     */
    user_id: string;
    /**
     * 邮编
     */
    zip_code: string;
};

export type RefundParams = {
    order_id: string;
    user_id: string;
};

export type RefundsParams = {
    /**
     * 退款申请结束时间
     */
    apply_time_end?: string;
    /**
     * 退款申请开始时间
     */
    apply_time_start?: string;
    /**
     * 退款id
     */
    id?: string;
    /**
     * 每页数量
     */
    limit?: number;
    /**
     * 订单id
     */
    order_id?: string;
    /**
     * 分页
     */
    page?: number;
    /**
     * 状态
     */
    status?: string;
    /**
     * 用户id
     */
    user_id?: string;

    fe_date?: any[];
};

export type RefundsResult = {
    /**
     * 实际退款
     */
    actual_refund: string;
    /**
     * 退款申请时间
     */
    apply_time: string;
    created_at: string;
    deleted_at: null;
    /**
     * 退款单id
     */
    id: string;
    /**
     * 用户名
     */
    name: string;
    /**
     * 订单id
     */
    order_id: string;
    /**
     * 退款时间
     */
    refund_time: string;
    /**
     * 退款状态  退款中 being_refunded，已退款 refunded
     */
    status: string;
    updated_at: string;
    /**
     * 用户id
     */
    user_id: string;
    /**
     * 用户名
     */
    user_name: string;
};

export type StockResult = {
    /**
     * 可用数量
     */
    available_quantity?: number;
    /**
     * 创建时间
     */
    create_time?: string;
    /**
     * 扣减数量
     */
    deducted_quantity?: number;
    /**
     * id
     */
    id?: string;
    /**
     * 锁定数量
     */
    locked_quantity?: number;
    /**
     * 数量
     */
    quantity?: number;
    /**
     * sku
     */
    sku?: string;
    /**
     * 规格
     */
    spec?: string;
    /**
     * 单价
     */
    unit_price?: number;
    /**
     * 更新时间
     */
    update_time?: string;
};

export type UpdateStockData = {
    /**
     * 数量 字符串
     */
    quantity: string;
    /**
     * sku
     */
    sku: string;
};

export type OrderManagerResult = {
    countryLimit: OrderManagerItem[];
    /**
     * 个人最大限制
     */
    userLimit: number;
};

export type OrderManagerItem = {
    /**
     * 国家
     */
    country: string;
    /**
     * 最大数量 小于10，字符串
     */
    max: string;
    /**
     * 操作人
     */
    operator: null;
    /**
     * 操作时间
     */
    operatorTime: number;
    /**
     * sku
     */
    sku: string;
    /**
     * 规格
     */
    specification: string;
    /**
     * 单价
     */
    unitPrice: string;
};

export type CountryLimitParams = {
    sku: string;
    country: string;
    max: string;
};
