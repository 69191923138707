import { IdType } from './creator-academy';
import axios from './index';

/**
 * APIs
 */
// 获取 预设问题列表
export const getPresetQuestion = (params: PresetQuestionParams) => {
    return axios<PageResType<PresetQuestionResult>>('/v1/ai/preset-question', {
        method: 'get',
        params,
    });
};

// 删除预设问题
export const dPresetQuestion = (id: IdType) => {
    return axios<PageResDataType<PresetQuestionResult>>(`/v1/ai/preset-question/${id}`, {
        method: 'delete',
    });
};

// 新增预设问题
export const cPresetQuestion = (data: CreatePresetQuestionParams) => {
    return axios<PageResDataType<PresetQuestionResult>>(`/v1/ai/preset-question`, {
        method: 'post',
        data,
    });
};

// 编辑预设问题
export const pPresetQuestion = (id: IdType, data: CreatePresetQuestionParams) => {
    return axios<PageResDataType<PresetQuestionResult>>(`/v1/ai/preset-question/${id}`, {
        method: 'put',
        data,
    });
};

/**
 * Types
 */
import type { OperatorAccount, PageResDataType, PageResType } from '@repo/typings/common';

export type PresetQuestionParams = {
    language?: string;
    limit?: number;
    page?: number;
    /**
     * 0 home， 1 more
     */
    type: '0' | '1';
};

export type PresetQuestionResult = {
    answer: string;
    created_at: string;
    id: string;
    languages: Languages;
    operator_account: OperatorAccount;
    question: string;
    type: string;
    updated_at: string;
};

export type CreatePresetQuestionParams = {
    languages: Languages;
    type?: 0 | 1;
};

export type Languages = Record<
    string,
    {
        answer: string;
        question: string;
    }
>;
