import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, PaginationProps, Popconfirm, Segmented, Spin, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import { dPresetQuestion, getPresetQuestion } from '@/services/preset-question';

/**
 * Components
 */
import ComponentDrawerFormCustom from './components/drawer-form-custom';

/**
 * Constants
 */

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { PresetQuestionResult } from '@/services/preset-question';

type State = {
    dataSource: PresetQuestionResult[];
    entity: PresetQuestionResult | null;
    openDrawer: boolean;
};

const PageClonePresetQuestions: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
    });
    const [cellLoading, setCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<any>({
        group: 'Home',
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading: loadingPresetQuestion,
        refresh: refreshPresetQuestion,
        run: runPresetQuestion,
    } = useRequest(getPresetQuestion, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setPagination({
                total: data.meta.total,
            });
            setState({
                dataSource: data.list,
            });
        },
    });

    // const { run: rundPresetQuestion} = useRequest(dPresetQuestion, {
    //     manual: true,
    //     onSuccess: () => {

    //     }
    // })

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<PresetQuestionResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="pq.t.c.qid" />,
                width: 200,
            },
            {
                dataIndex: ['languages', 'EN', 'question'],
                ellipsis: true,
                title: (
                    <>
                        <FormattedMessage id="pq.t.c.question" /> （EN）
                    </>
                ),
                width: 200,
            },
            {
                dataIndex: ['languages', 'EN', 'answer'],
                ellipsis: true,
                title: (
                    <>
                        <FormattedMessage id="pq.t.c.answer" /> （EN）
                    </>
                ),
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <a
                        key="edit"
                        onClick={() => {
                            setState({
                                entity,
                                openDrawer: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.edit" />
                    </a>,
                    <Spin spinning={!!cellLoading[entity.id]} size="small" key="delete">
                        <Popconfirm
                            title={intl.formatMessage({ id: 'c.areyousure' })}
                            onConfirm={() => {
                                setCellLoading({ [entity.id]: true });
                                dPresetQuestion(entity.id)
                                    .then(({ data: { code, msg } }) => {
                                        setCellLoading({ [entity.id]: false });
                                        if (code !== 0) {
                                            return message.error(msg || 'Error');
                                        }
                                        message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                        refreshPresetQuestion();
                                    })
                                    .catch(() => {
                                        setCellLoading({ [entity.id]: false });
                                        message.error('Error');
                                    });
                            }}
                        >
                            <Typography.Link type="danger">
                                <FormattedMessage id="b.delete" />
                            </Typography.Link>
                        </Popconfirm>
                    </Spin>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingPresetQuestion,
        options: {
            reload: refreshPresetQuestion,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1170,
            y: `calc(100vh - 272px)`,
        },
        search: false,
        toolBarRender: () => {
            return [
                <Segmented<'Home' | 'More'>
                    options={['Home', 'More']}
                    value={payload.group}
                    onChange={(value) => {
                        setPayload({ group: value });
                        setPagination({ current: 1 });
                    }}
                    key="group"
                />,
                <Button
                    type="primary"
                    onClick={() => {
                        setState({ openDrawer: true, entity: null });
                    }}
                >
                    <FormattedMessage id="b.add" />
                </Button>,
            ];
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runPresetQuestion({
            type: payload.group === 'More' ? '1' : '0',
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [payload, pagination.current, pagination.pageSize]);

    return (
        <>
            <ProTable {...proTableProps} />
            <ComponentDrawerFormCustom
                open={state.openDrawer}
                entity={state.entity}
                type={payload.group}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshPresetQuestion();
                }}
            />
        </>
    );
};

export default PageClonePresetQuestions;
