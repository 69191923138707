import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Space } from 'antd';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { addCelebrity } from '@/services/user';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { AddCreatorPostsParams } from '@/services/creator-posts';

type Props = {
    open: boolean;
    closeModelForm: (reload?: boolean) => void;
};

const ComponentModalFormCustom: React.FC<Props> = ({ open, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData: AddCreatorPostsParams) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await addCelebrity(formData);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="b.add" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item name="user_id" label={'UID'} rules={[{ required: true }]}>
                    <Input allowClear showCount maxLength={50} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
