export default {
    p: {
        uu: {
            h: {
                status: '状态',
                baseInfo: '基本信息',
            },
            columns: {
                uid: 'UID',
                userName: '用户名',
                nickname: '昵称',
                bio: '个人简介',
                parentUid: '上级UID',
                registrationTime: '注册时间',
                remark: '备注',
            },
            status: {
                permanent: '永久',
                account: {
                    normal: '正常',
                    disabled: '封号',
                    disabledAt: '封号时间',
                    enabledAt: '解封时间',
                    freeze: 'Frozen',
                },
                muted: {
                    normal: '正常',
                    muted: '禁言',
                    mutedAt: '禁言时间',
                    unMutedAt: '解封时间',
                },
            },
            action: {
                mute: '禁言',
                ban: '封号',
                muteTemporary: '临时禁言',
                mutePermanent: '永久禁言',
                banTemporary: '临时封号',
                banPermanent: '永久封号',
                unmute: '解除禁言',
                unban: '解除封号',
                avatar: '重置头像',
                name: '重置用户名',
                nickname: '重置昵称',
                bio: '重置个人简介',
                freezePermanent: '永久冻结',
                freeze: '冻结资金',
                unfreeze: '解冻资金',
                liveDisable: '禁播',
                unliveDisable: '解除禁播',
                liveDisableTemporary: '临时禁播',
                liveDisablePermanent: '永久禁播',
            },
            btn: {
                mangProfile: '管理资料',
                mute: '禁言',
                unmute: '解除禁言',
                disable: '封号',
                enable: '解封',
                freezeFunds: '冻结资金',
                unfreezeFunds: '解冻资金',
            },
            cycleType: {
                minute: '分钟',
                hour: '小时',
                day: '天',
            },
            message: {
                cycleNumber: '请输入1-1000之间的数字',
            },
            t: {
                c: {
                    school: '学校',
                    mobile: '手机号',
                    birthday: '出生日期',
                },
            },
        },
    },
};
