import { useEffect, useRef } from 'react';
import { App, Button, Flex, Form, Popconfirm, Spin, Typography } from 'antd';
import { ProCard, ProTable, type ProTableProps } from '@ant-design/pro-components';
import { get, isEmpty, omitBy } from 'lodash';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';

/**
 * Components
 */
import { Pagination } from '@repo/ui/pagination';
import Permission from '@/components/permission';
import ComponentAddDrawerForm from './components/drawer-form-custom';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { dCelebrity, exportCelebrity, gUser } from '@/services/user';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { UserParams, UserResult } from '@/services/user';
import type { PaginationProps } from '@repo/ui/pagination';

type State = {
    dataSource: UserResult[];
    openDrawer: boolean;
};

/**
 * Constants
 */
const defaultPayload = {
    nickname: undefined,
    id: undefined,
    username: undefined,
};

const PageCelebrities: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const intl = useIntl();

    // const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useSetState<State>({
        dataSource: [],
        openDrawer: false,
    });

    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<UserParams>({
        ...defaultPayload,
        is_celebrity: 'true',
        'sort[0][updated_at]': 'desc',
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshUser,
        run: runUser,
    } = useRequest(gUser, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });

            setState({
                dataSource: data.map((entity: any) => {
                    // 匹配最新那条名人相关的操作记录
                    const log_operation = (entity.log_operation || []).reverse().find((v: any) => {
                        return v.after.is_celebrity;
                    });
                    return {
                        ...entity,
                        updated_at: log_operation ? log_operation.created_at : undefined,
                        operator_account_name: log_operation ? log_operation.operator.name : undefined,
                    };
                }),
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            const toServerParams = {
                id: values.user_id,
                nickname: values.nickname,
                username: values.username,
            };

            setPagination({
                current: 1,
                searchAfter: '',
            });
            setPayload({
                ...defaultPayload,
                ...omitBy(toServerParams, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<UserResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                render: (_, entity) => {
                    const { username = '-', nickname = '-' } = entity;

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.id, tooltips: 'Copy UID' }}>
                                @{username}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'operator_account_name',
                width: 200,
                title: <FormattedMessage id="t.c.operatedBy" />,
            },
            {
                dataIndex: 'updated_at',
                width: 200,
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Users_Celebrities_Delete" key="delete">
                        <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellViolateLoading({
                                        [entity.id]: true,
                                    });
                                    dCelebrity(entity.id)
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                setTimeout(() => {
                                                    refreshUser();
                                                    setTableCellViolateLoading({
                                                        [entity.id]: false,
                                                    });
                                                }, 1000);
                                                return;
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellViolateLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination: false,
        options: {
            reload: refreshUser,
        },
        rowKey: 'id',
        scroll: {
            x: 1080,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertRender: false,
        toolBarRender: () => {
            return [
                <Permission permission="Users_Celebrities_Add" key="add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({
                                openDrawer: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
            ];
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runUser({
            ...payload,
            size: `${pagination.size}`,
            search_after: pagination.searchAfter,
        });
    }, [pagination.current, pagination.size, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                                formSearch.setFieldValue('date', [dayjs().startOf('d'), dayjs().endOf('d')]);
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Users_Celebrities_Export">
                            <Button
                                onClick={async () => {
                                    const { data } = await exportCelebrity({
                                        // ...omitBy(values, isEmpty),
                                    });
                                    if (data.code !== 0) {
                                        return message.error(data.msg);
                                    }
                                    message.success(intl.formatMessage({ id: 'm.exportTips' }));
                                }}
                            >
                                <FormattedMessage id="b.export" />
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loading} />

            <ComponentAddDrawerForm
                open={state.openDrawer}
                closeModelForm={(reload) => {
                    setState({ openDrawer: false });
                    reload && refreshUser();
                }}
            />
        </>
    );
};

export default PageCelebrities;
