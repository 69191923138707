import { useEffect } from 'react';
import { App, Button, Popconfirm, Typography } from 'antd';
import { ProTable, type ProTableProps } from '@ant-design/pro-components';
import type { ModalFormState } from './components/incentive-seasons-modal-form';
import { useRequest, useSetState } from 'ahooks';
import Permission from '@/components/permission';
import IncentiveSeasonsModalForm from './components/incentive-seasons-modal-form';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { incentivesSocApi, deleteIncentivesByIdApi, incentivesListApi } from '@/services/incentive-seasons';
import { getAccountFaceActiveCountApi } from '@/services/account-management';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type { PaginationProps } from 'antd';
import type { IncentiveSeasonItem } from '@/services/incentive-seasons';
import dayjs from 'dayjs';
import { IncentiveSeasonsTokenMap } from '@/const/incentive-seasons-tokens';

type State = {
    dataSource: IncentiveSeasonItem[];
    selectedRowKeys: React.Key[];
    modalFormState: ModalFormState;
    socCount: number;
    faceActiveCount: number;
};

const PageIncentiveSeasons: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const [state, setState] = useSetState<State>({
        dataSource: [],
        selectedRowKeys: [],
        modalFormState: {
            open: false,
            data: undefined,
        },
        socCount: 0,
        faceActiveCount: 0,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading,
        refresh: refreshIncentivesList,
        run: runUserNameList,
    } = useRequest(incentivesListApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    useRequest(incentivesSocApi, {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                socCount: data?.soc_amount ?? 0,
            });
        },
    });

    useRequest(getAccountFaceActiveCountApi, {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                faceActiveCount: data.user_count,
            });
        },
    });

    const proTableProps: ProTableProps<IncentiveSeasonItem, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                valueType: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
            },
            {
                dataIndex: 'activity_id',
                title: <FormattedMessage id="p.inc.column.id" />,
                width: 200,
            },
            {
                dataIndex: 'time_range',
                title: <FormattedMessage id="p.inc.column.timeRange" />,
                valueType: 'dateTimeRange',
                render: (_, entity) => {
                    return [
                        dayjs(entity.start_at).format('YYYY-MM-DD HH:mm:ss'),
                        '~',
                        dayjs(entity.end_at).format('YYYY-MM-DD HH:mm:ss'),
                    ];
                },
            },
            {
                dataIndex: 'target',
                width: 280,
                title: <FormattedMessage id="p.inc.column.target" />,
                tooltip: <FormattedMessage id="p.inc.column.targetTooltips" />,
                render: (_, entity) => {
                    return entity.target.map((item, index) => {
                        return (
                            <div key={index}>
                                {item.user}: {item.token_amount || item.soc} {IncentiveSeasonsTokenMap[entity.token_id]}
                            </div>
                        );
                    });
                },
            },
            {
                dataIndex: 'status',
                width: 120,
                title: <FormattedMessage id="p.inc.column.status" />,
                valueEnum: {
                    pending: <FormattedMessage id="p.inc.status.pending" />,
                    running: <FormattedMessage id="p.inc.status.running" />,
                    completed: <FormattedMessage id="p.inc.status.completed" />,
                },
            },
            {
                dataIndex: ['operator_account', 'name'],
                width: 120,
                title: <FormattedMessage id="t.c.createdBy" />,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.createdAt" />,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => {
                    // if (entity.status !== 'pending') {
                    //     return '-';
                    // }

                    const editButton = (
                        <Permission permission="Incentives_Incentive-Seasons_Edit" key="edit">
                            <a
                                onClick={() => {
                                    setState({
                                        modalFormState: {
                                            open: true,
                                            data: entity,
                                        },
                                    });
                                }}
                            >
                                <FormattedMessage id="common.edit" />
                            </a>
                        </Permission>
                    );

                    const deleteButton = (
                        <Permission permission="Incentives_Incentive-Seasons_Delete" key="delete">
                            <Popconfirm
                                title={<FormattedMessage id="c.areyousure" />}
                                onConfirm={() => {
                                    if (!entity.id) {
                                        return;
                                    }
                                    deleteIncentivesByIdApi(entity.id).then(() => {
                                        message.success(intl.formatMessage({ id: 'common.deleteOk' }));
                                        refreshIncentivesList();
                                    });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="common.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Permission>
                    );

                    const hasEditPermission = editButton.props.children !== null;
                    const hasDeletePermission = deleteButton.props.children !== null;

                    if (!hasEditPermission && !hasDeletePermission) {
                        return '-';
                    }

                    const btnGroup = [];

                    if (hasEditPermission && entity.status !== 'completed') {
                        btnGroup.push(editButton);
                    }
                    if (hasDeletePermission && entity.status === 'pending') {
                        btnGroup.push(deleteButton);
                    }

                    if (!btnGroup.length) {
                        return '-';
                    }

                    return btnGroup;
                },
            },
        ],
        dataSource: state.dataSource,
        loading,
        pagination,
        options: {
            reload: refreshIncentivesList,
        },
        rowKey: 'id',
        toolbar: {
            title: (
                <>
                    <span style={{ marginRight: 32, fontWeight: 'normal' }}>
                        <FormattedMessage id="p.inc.column.faceActiveCount" />: {state.faceActiveCount}
                    </span>
                    <span style={{ fontWeight: 'normal' }}>
                        <FormattedMessage id="p.inc.column.socCount" />: {state.socCount}
                    </span>
                </>
            ),
        },
        toolBarRender: () => {
            return [
                <Permission key="create" permission="Incentives_Incentive-Seasons_Add">
                    <Button
                        type="primary"
                        onClick={() => {
                            setState({
                                modalFormState: {
                                    open: true,
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="b.add" />
                    </Button>
                </Permission>,
            ];
        },
        scroll: {
            y: 'calc(100vh - 328px)',
            x: 1500,
        },
        search: false,
        tableAlertRender: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runUserNameList({
            page: pagination.current,
            limit: pagination.pageSize,
        });
    }, [pagination.current, pagination.pageSize]);

    return (
        <>
            <ProTable {...proTableProps} />

            <IncentiveSeasonsModalForm
                state={state.modalFormState}
                reload={refreshIncentivesList}
                onClose={() => {
                    setState({
                        modalFormState: {
                            open: false,
                            data: undefined,
                        },
                    });
                }}
            />
        </>
    );
};

export default PageIncentiveSeasons;
