import dayjs from 'dayjs';

const defaultFormat = 'YYYY-MM-DD HH:mm:ss';

export const dateTimeFormate = (time: string | number, format = defaultFormat) => {
    if (typeof time === 'string') {
        return dayjs(time).format(format);
    }
    // 10位时间戳 补全毫秒数
    if (`${time}`.length === 10) {
        time = time * 1000;
    }
    return dayjs(time).format(format);
};
