export default {
    p: {
        ambassadors: {
            b: {
                bulkApprove: 'Bulk Approve',
                bulkAssign: 'Bulk Assign',
                changeGroup: '转组',
            },
            f: {
                Ambassador: 'Ambassador',
                AmbassadorL2: 'Tier-2 Ambassador',
                Title: 'Title',
                Description: 'Description',
                RewardPoints: 'Reward Points',
            },
            t: {
                c: {
                    activeUserTotal: 'Active Users',
                    assigned: 'Assigned',
                    followers: 'Followers',
                    pending: 'Pending',
                    postTotal: 'Posts',
                    postUserTotal: 'Users Posted',
                    registerTotal: 'Invited Users',
                    realnameTotal: 'Users Verified',
                    reviewResult: 'Result',
                    socialMedia: 'Social Media',
                    status: 'Status',
                    unassigned: 'Unassigned',
                    revokeReason: 'Reason',
                    Ambassadors: 'Ambassadors',
                },
            },
            tips: {
                privatePool: {
                    email: 'The email that the user enters when applying to become an ambassador',
                },
                ambassadorData: {
                    uid: 'Ambassador User ID',
                    posts: 'Number of posts by ambassador',
                    invitedUsers: 'Number of registered users invited by ambassador',
                    usersPosted: 'Number of users who posted',
                    usersVerified: 'Number of users with successful liveness verification',
                },
                ambassador: {
                    unbound: 'Are you sure you want to release this ambassador?',
                },
                groupMemberTop: '请点击左侧各组进行人员配置',
            },
            detail: {
                languages: 'Languages',
                occupationDetails: 'Occupation Details',
                motivation: 'Motivation',
                promotionPlan: 'Promotion Plan',
                promotionExperience: 'Promotion Experience',
                questions: 'Questions',
                timeWillingToSpend: 'Time Willing To Spend',
                web3Experience: 'Web3 Experience',
                web3Knowledge: 'Knowledge of Web3',
                willingnessToLead: 'Willingness To Lead',
                satisfyCurrentLife: 'Satisfaction with current life situation',
                needChangeLife: 'Desire for platform and opportunities',
                bestChangeLife: 'Willingness to give full effort',
            },
            privatePool: {
                rejectReason: 'Reason',
            },
            publicPool: {
                status: {
                    normal: 'Normal',
                    unbound: 'Released',
                    revoked: 'Revoked',
                },
            },
        },
    },
};
