import { useEffect } from 'react';
import { useBoolean } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Form, Button, Input, App, Row, Col, DatePicker, Flex, Select } from 'antd';
import { createIncentivesApi, updateIncentivesApi } from '@/services/incentive-seasons';
import { get, isEmpty, omitBy } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

/**
 * Constants
 */
import { IncentiveSeasonsTokenOptions } from '@/const/incentive-seasons-tokens';

/**
 * Types
 */
import type { IntlShape } from 'react-intl';
import type {
    IncentiveSeasonCreateItem,
    IncentiveSeasonItem,
    IncentiveSeasonTarget,
} from '@/services/incentive-seasons';

export type ModalFormState = {
    open: boolean;
    data?: IncentiveSeasonItem;
};

type Props = {
    state: ModalFormState;
    reload?: () => void;
    onClose: () => void;
};

const ModalFormUserName: React.FC<Props> = ({ state, onClose, reload }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const intl: IntlShape = useIntl();

    /**
     * Effect
     */
    useEffect(() => {
        if (!state.open) {
            form.resetFields();
            form.setFieldValue('target', [{ user: '', soc: '' }]);
            setSubmittingFlase();
        } else if (state.data) {
            form.setFieldsValue({
                target: state.data.target,
                start_at: dayjs(state.data.start_at),
                end_at: dayjs(state.data.end_at),
                status: state.data.status,
                token_id: state.data.token_id,
            });
        }
    }, [state]);

    const handleFinish = async (formData: any) => {
        setSubmittingTrue();
        const { start_at, end_at, target, token_id } = formData;
        const newTarget: IncentiveSeasonTarget[] = target.map((item: any) => ({
            user: Number(item.user),
            token_amount: Number(item.token_amount),
        }));
        const submitBaseData: IncentiveSeasonCreateItem = {
            target: newTarget,
            start_at,
            end_at,
        };
        // update
        if (state.data && state.data.id) {
            submitBaseData.updated_at = state.data.updated_at;
            if (state.data.status === 'running') {
                submitBaseData.start_at = undefined;
            }
            const res = await updateIncentivesApi(state.data.id, {
                ...omitBy(submitBaseData, isEmpty),
            } as IncentiveSeasonCreateItem);
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose();
                reload?.();
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
            // create
        } else {
            submitBaseData.token_id = token_id;
            const res = await createIncentivesApi(submitBaseData);
            if (get(res, 'data.code') === 0) {
                message.success(intl.formatMessage({ id: 'common.submitSuccess' }));
                onClose();
                reload?.();
            } else {
                message.error(get(res, 'data.msg', intl.formatMessage({ id: 'common.undefinedError' })));
            }
        }
        setSubmittingFlase();
    };

    /**
     * ChildrenProps
     */
    const drawerProps = {
        title: state.data?.id ? <FormattedMessage id="b.edit" /> : <FormattedMessage id="b.add" />,
        onClose: () => onClose(),
        maskClosable: false,
        open: state.open,
        destroyOnClose: true,
        size: 'large',
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                </Button>
                <Button onClick={() => form.submit()} type="primary" loading={submitting}>
                    {intl.formatMessage({ id: 'common.sure' })}
                </Button>
            </div>
        ),
    };

    return (
        <Drawer {...drawerProps} size="large">
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="start_at"
                            label={<FormattedMessage id="p.inc.column.startTime" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <DatePicker
                                disabled={state.data && state.data.status === 'running'}
                                showTime={{
                                    hideDisabledOptions: true,
                                }}
                                allowClear={true}
                                disabledDate={(current) => current && current < dayjs().startOf('day')}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="end_at"
                            label={<FormattedMessage id="p.inc.column.endTime" />}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'common.notEmpty' }) }]}
                        >
                            <DatePicker
                                showTime={{
                                    hideDisabledOptions: true,
                                }}
                                allowClear={true}
                                disabledDate={(current) => current && current < dayjs().startOf('day')}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="token_id" label={'Token'} rules={[{ required: true }]}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                disabled={!!state.data}
                                filterOption={(input, option) => {
                                    const label = get(option, 'children[1]', '');
                                    return label.toLowerCase().includes(input.toLowerCase());
                                }}
                            >
                                {IncentiveSeasonsTokenOptions.map((v) => {
                                    return (
                                        <Select.Option key={v.token_id} value={v.token_id}>
                                            <img
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginRight: '8px',
                                                    borderRadius: '50%',
                                                }}
                                                src={v.url}
                                            />
                                            {v.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.List name="target">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ name, ...restField }, index) => (
                                <Flex wrap gap="small" style={{ marginBottom: 8 }} key={index}>
                                    <Flex flex={1} key="user">
                                        <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            name={[name, 'user']}
                                            label={intl.formatMessage(
                                                { id: 'p.inc.column.user' },
                                                { value: index + 1 },
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ id: 'common.notEmpty' }),
                                                },
                                                {
                                                    type: 'number',
                                                    min: 1,
                                                    max: 100000000,
                                                    transform: (value) => Number(value),
                                                    message: intl.formatMessage({
                                                        id: 'p.inc.msg.user',
                                                    }),
                                                },
                                            ]}
                                            key="user"
                                        >
                                            <Input type="number" maxLength={9} />
                                        </Form.Item>
                                    </Flex>
                                    <Flex flex={1} key="token_amount">
                                        <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            name={[name, 'token_amount']}
                                            label={intl.formatMessage({ id: 'p.inc.column.soc' }, { value: index + 1 })}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ id: 'common.notEmpty' }),
                                                },
                                                {
                                                    pattern: /^\d{1,11}(\.\d{1,4})?$/,
                                                    message: intl.formatMessage({ id: 'p.inc.msg.soc' }),
                                                },
                                            ]}
                                            key="token_amount"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Flex>
                                    <Flex style={{ cursor: 'pointer', minWidth: 24 }} key="add">
                                        <MinusCircleOutlined
                                            style={{ cursor: fields.length > 1 ? 'pointer' : 'not-allowed' }}
                                            onClick={() => {
                                                if (fields.length > 1) {
                                                    remove(name);
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            {fields.length < 4 && (
                                <Form.Item key="add">
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage id="b.add" />
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
            </Form>
        </Drawer>
    );
};

export default ModalFormUserName;
