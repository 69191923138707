import { useState } from 'react';
import { useRequest } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, InputNumber, Segmented, Space, Spin } from 'antd';

/**
 * APIs
 */
import { gAmbIncentiveSeasonsConfig, pAmbIncentiveSeasonsConfig } from '@/services/ambassadors-incentive-seasons';

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { Languages } from '@/services/preset-question';

type LanguageType = (typeof Language)[number];

type Props = {
    open: boolean;
    close: (reload?: boolean) => void;
};

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP', 'PT'];

const ComponentConfigureModalForm: React.FC<Props> = ({ open, close }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [form] = Form.useForm();
    const [showLanguage, setShowLanguage] = useState('EN');

    /**
     * Request
     */
    const { run: runGetConfig, loading } = useRequest(gAmbIncentiveSeasonsConfig, {
        manual: true,
        onSuccess: ({ data: { data, code, msg } }) => {
            if (code !== 0) {
                message.error(msg);
                return;
            }
            form.setFieldsValue({
                id: data.id,
                points_lv1: data.points_lv1,
                points_lv2: data.points_lv2,
                languages: data.languages,
            });
        },
    });

    const { run: runSave, loading: saveLoading } = useRequest(pAmbIncentiveSeasonsConfig, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                message.error(msg);
                return;
            }
            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            close(true);
        },
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => close()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={saveLoading}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    console.log(formData);
                                    runSave(formData.id, {
                                        points_lv1: formData.points_lv1,
                                        points_lv2: formData.points_lv2,
                                        languages: formData.languages,
                                    });
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        size: 'large',
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="pm.Configure" />,
        afterOpenChange: (open) => {
            if (!open) {
                setShowLanguage('EN');
                form.resetFields();
            } else {
                runGetConfig();
            }
        },
        onClose: () => {
            close();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<Languages> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Spin spinning={loading}>
                <Form {...formProps}>
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="_language"
                        label={intl.formatMessage({ id: 'tc.f.language' })}
                        rules={[{ required: true }]}
                        initialValue={'EN'}
                    >
                        <Segmented<LanguageType>
                            options={Language}
                            value={showLanguage}
                            onChange={(value) => {
                                setShowLanguage(value);
                                form.setFieldValue('_language', value);
                            }}
                        />
                    </Form.Item>
                    {Language.map((v) => {
                        return (
                            <div key={v} style={{ display: showLanguage === v ? 'block' : 'none' }}>
                                <Form.Item
                                    name={['languages', v, 'title_lv1']}
                                    label={
                                        intl.formatMessage({ id: 'p.ambassadors.f.Title' }) +
                                        `（${intl.formatMessage({ id: 'p.ambassadors.f.Ambassador' })}）`
                                    }
                                    rules={[{ required: 'EN' === v }]}
                                    initialValue=""
                                >
                                    <Input showCount maxLength={200} />
                                </Form.Item>

                                <Form.Item
                                    name={['languages', v, 'description_lv1']}
                                    label={
                                        intl.formatMessage({ id: 'p.ambassadors.f.Description' }) +
                                        `（${intl.formatMessage({ id: 'p.ambassadors.f.Ambassador' })}）`
                                    }
                                    rules={[{ required: 'EN' === v }]}
                                    initialValue=""
                                >
                                    <Input showCount maxLength={200} />
                                </Form.Item>
                            </div>
                        );
                    })}

                    <Form.Item
                        name="points_lv1"
                        label={
                            intl.formatMessage({ id: 'p.ambassadors.f.RewardPoints' }) +
                            `（${intl.formatMessage({ id: 'p.ambassadors.f.Ambassador' })}）`
                        }
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            max={99999999999.9999}
                            min={0.0001}
                            precision={4}
                            stringMode
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    {Language.map((v) => {
                        return (
                            <div key={v} style={{ display: showLanguage === v ? 'block' : 'none' }}>
                                <Form.Item
                                    name={['languages', v, 'title_lv2']}
                                    label={
                                        intl.formatMessage({ id: 'p.ambassadors.f.Title' }) +
                                        `（${intl.formatMessage({ id: 'p.ambassadors.f.AmbassadorL2' })}）`
                                    }
                                    rules={[{ required: 'EN' === v }]}
                                    initialValue=""
                                >
                                    <Input showCount maxLength={200} />
                                </Form.Item>

                                <Form.Item
                                    name={['languages', v, 'description_lv2']}
                                    label={
                                        intl.formatMessage({ id: 'p.ambassadors.f.Description' }) +
                                        `（${intl.formatMessage({ id: 'p.ambassadors.f.AmbassadorL2' })}）`
                                    }
                                    rules={[{ required: 'EN' === v }]}
                                    initialValue=""
                                >
                                    <Input showCount maxLength={200} />
                                </Form.Item>
                            </div>
                        );
                    })}

                    <Form.Item
                        name="points_lv2"
                        label={
                            intl.formatMessage({ id: 'p.ambassadors.f.RewardPoints' }) +
                            `（${intl.formatMessage({ id: 'p.ambassadors.f.AmbassadorL2' })}）`
                        }
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            stringMode
                            max={99999999999.9999}
                            min={0.0001}
                            precision={4}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
};

export default ComponentConfigureModalForm;
