import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, last, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '@/components/table-cell/table-cell-media';
import ComponentTableCellDebate from '@/pages/content-management/posts/components/table-cell-debate';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { dAudioLive, dVideoLive, closeAudioLive, closeVideoLive, gPBVideoLive, gPBAudioLive } from '@/services/live';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { LiveRoomParams, LiveRoomResult, PBLiveParams } from '@/services/live';
import { Pagination, PaginationProps } from '@repo/ui/pagination';

/**
 * Constants
 */
const liveStatusMap = {
    pending: <FormattedMessage id="cl.c.noStart" />,
    live: <FormattedMessage id="cl.c.liveing" />,
    end: <FormattedMessage id="cl.c.finished" />,
};

const liveOptions = Object.entries(liveStatusMap).map((v) => {
    return {
        label: v[1],
        value: v[0],
    };
});

const liveTypeMap = {
    1: <FormattedMessage id="cl.s.audio" />,
    2: <FormattedMessage id="t.c.video" />,
};

const liveTypeOptions = Object.entries(liveTypeMap).map((v) => {
    return {
        label: v[1],
        value: v[0],
    };
});

/**
 * State
 */
type State = {
    dataSource: LiveRoomResult[];
    type: 'audio' | 'video';
};

const PageContentLive: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        type: 'audio',
    });

    const [tableCellCloseLoading, setTableCellCloseLoading] = useSetState<Record<string, boolean>>({});
    const [tableCellDeleteLoading, setTableCellDeleteLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<LiveRoomParams>({});

    // 视频直播
    const {
        loading: loadingVideoLive,
        refresh: refreshVideoLive,
        run: runVideoLive,
    } = useRequest(gPBVideoLive, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });
        },
    });

    // 语音直播
    const {
        loading: loadingAudioLive,
        refresh: refreshAudioLive,
        run: runAudioLive,
    } = useRequest(gPBAudioLive, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<PBLiveParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.create_date) {
                values.created_start = values.create_date[0].startOf('d').format();
                values.created_end = values.create_date[1].endOf('d').format();
                values.create_date = undefined;
            }

            if (values.operation_date) {
                values['updated_at[0]'] = values.operation_date[0].startOf('d').format();
                values['updated_at[1]'] = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }
            if (values.debate_fuzzy) {
                values.debate_fuzzy = trim(values.debate_fuzzy);
            }
            if (values.id) {
                values.id = trim(values.id);
            }

            if (values.user_id) {
                values.user_id = trim(values.user_id);
            }

            values.fe_is_deleted = undefined;

            setPagination({
                current: 1,
                searchAfter: '',
            });
            setState({
                type: (values as any).type === '1' ? 'audio' : 'video',
            });

            setPayload({
                ...omitBy({ ...values, type: undefined }, isEmpty),
            });
        },
    };

    const loading = state.type === 'audio' ? loadingAudioLive : loadingVideoLive;
    const refresh = state.type === 'audio' ? refreshAudioLive : refreshVideoLive;

    const proTableProps: ProTableProps<LiveRoomResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="cl.t.c.homeId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="cl.t.c.createrId" />,
                width: 200,
            },
            {
                dataIndex: ['debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="ctp.t.c.topic" />,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        forTxt={get(entity, 'debate.for_text', null) as any}
                        against={get(entity, 'debate.against_text', null) as any}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="cad.t.c.cover" />,
                render: (_, entity) => {
                    const coverUrl = get(entity, 'cover', '');
                    if (!coverUrl) {
                        return '-';
                    }
                    return <TableCellMedia list={[coverUrl]} />;
                },
                width: 200,
            },
            {
                dataIndex: 'start_at',
                title: <FormattedMessage id="cl.t.c.startAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'end_at',
                title: <FormattedMessage id="cl.t.c.endAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'type_competition',
                title: <FormattedMessage id="cl.t.c.liveMode" />,
                render: (text) => {
                    if (!text) {
                        return '-';
                    }
                    return <span>{`${text}v${text}`}</span>;
                },
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="cl.t.c.liveStatus" />,
                valueEnum: liveStatusMap,
                width: 120,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.is_deleted
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'type',
                title: <FormattedMessage id="t.c.type" />,
                width: 120,
                render: () => {
                    return liveTypeMap[state.type === 'audio' ? '1' : '2'];
                },
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.createdAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                render: (_, entity) => {
                    return get(last(get(entity, 'log_operation')), 'operator.name') || '-';
                },
                width: 200,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    !entity.deleted_at && entity.status === 'live' && (
                        <Permission permission="Content_Live_End" key="closeLive">
                            <Spin spinning={!!tableCellCloseLoading[entity.id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellCloseLoading({
                                            [entity.id]: true,
                                        });
                                        const closeLive = state.type === 'audio' ? closeAudioLive : closeVideoLive;
                                        closeLive({
                                            user_id: entity.user_id,
                                            room_id: entity.id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refresh();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellCloseLoading({
                                                    [entity.id]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellCloseLoading({
                                                    [entity.id]: false,
                                                });
                                            });
                                    }}
                                >
                                    <a>
                                        <FormattedMessage id="cl.b.closeLive" />
                                    </a>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                    !entity.deleted_at && (entity.status === 'pending' || entity.status === 'end') && (
                        <Permission permission="Content_Live_Delete" key="delete">
                            <Spin spinning={!!tableCellDeleteLoading[entity.id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellDeleteLoading({
                                            [entity.id]: true,
                                        });
                                        const dLive = state.type === 'audio' ? dAudioLive : dVideoLive;
                                        dLive({
                                            user_id: entity.user_id,
                                            room_id: entity.id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refresh();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellDeleteLoading({
                                                    [entity.id]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellDeleteLoading({
                                                    [entity.id]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loading,
        options: {
            reload: refresh,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 2770,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        const params = {
            ...payload,
            size: `${pagination.size}`,
            search_after: pagination.searchAfter,
        };
        state.type === 'audio' ? runAudioLive(params) : runVideoLive(params);
    }, [pagination.current, pagination.size, payload, state.type]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cl.t.c.homeId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cl.t.c.createrId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'ctp.t.c.topic' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={liveOptions}
                                placeholder={<FormattedMessage id="cl.t.c.liveStatus" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="type" style={{ marginRight: 0 }} initialValue="1">
                            <Select
                                allowClear={false}
                                showSearch={true}
                                optionFilterProp="label"
                                options={liveTypeOptions}
                                placeholder={<FormattedMessage id="t.c.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.createdAt' })}
                            name="create_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item name="operator_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Live" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loading} />
        </>
    );
};

export default PageContentLive;
