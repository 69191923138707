export default {
    cl: {
        b: {
            closeLive: '关播',
        },
        t: {
            c: {
                createrId: '创建者UID',
                homeId: '房间ID',
                endAt: '结束时间',
                liveMode: '赛制',
                liveStatus: '开播状态',
                startAt: '开始时间',
            },
        },
        c: {
            noStart: '待开播',
            liveing: '直播中',
            finished: '已结束',
        },
        s: {
            audio: '语音',
        },
    },
};
