import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, PaginationProps, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import { gStocks } from '@/services/mobile-store';

/**
 * Components
 */

/**
 * Constants
 */

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import Permission from '@/components/permission';
import type { StockResult } from '@/services/mobile-store';
import ComponentEditDrawer from './components/edit-drawer';

type State = {
    dataSource: StockResult[];
    entity: StockResult | null;
    openDrawer: boolean;
};

const PageMobileStoreInventory: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading: loadingApi,
        refresh: refreshApi,
        run: runApi,
    } = useRequest(gStocks, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setPagination({
                total: data.meta.total,
            });
            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<StockResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'sku',
                title: 'SKU',
                width: 200,
            },
            {
                dataIndex: 'spec',
                title: <FormattedMessage id="ms.t.c.specification" />,
            },
            {
                dataIndex: 'unit_price',
                title: <FormattedMessage id="ms.t.c.unitPrice" />,
                width: 200,
            },
            {
                dataIndex: 'available_quantity',
                title: <FormattedMessage id="ms.t.c.availableInventory" />,
                width: 200,
            },
            {
                dataIndex: 'quantity',
                title: <FormattedMessage id="ms.t.c.adjustedInventory" />,
                width: 200,
            },

            {
                dataIndex: 'create_time',
                title: <FormattedMessage id="t.c.updatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'operator',
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'operator_time',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Mobile-Store_Inventory_Edit" key="edit">
                        <Typography.Link onClick={() => setState({ openDrawer: true, entity })}>
                            <FormattedMessage id="b.edit" />
                        </Typography.Link>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingApi,
        options: {
            reload: refreshApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1850,
            y: `calc(100vh - 280px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runApi({
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize]);

    return (
        <>
            <ProTable {...proTableProps} />

            <ComponentEditDrawer
                entity={state.entity}
                open={state.openDrawer}
                closeCallback={(reload) => {
                    setState({ openDrawer: false });
                    if (reload) {
                        refreshApi();
                    }
                }}
            />
        </>
    );
};

export default PageMobileStoreInventory;
