import axios from './index';

/**
 * APIs
 */
// 获取报名信息
export function gRegistrantInformation(type: RegistrantInformationType, params: RegistrantInformationParams) {
    return axios<PageResType<RegistrantInformationResult>>(`/v1/competition/${type}/registrations`, {
        method: 'get',
        params,
    });
}

// 导出报名信息
export function exportRegistrantInformation(type: RegistrantInformationType, params: RegistrantInformationParams) {
    return axios<PageResType<RegistrantInformationResult>>(`/v1/competition/${type}/registrations/export/async`, {
        method: 'get',
        params,
    });
}

/**
 * Types
 */
import { PageResType } from '@repo/typings/common';

export enum RegistrantInformationType {
    Global = '3c4c0c45-d14c-4e37-8228-892428796dd0',
    Web3 = 'f107bdfb-695b-4230-976c-df260cac223a',
}

export type RegistrantInformationParams = {
    /**
     * 邮箱
     */
    email?: string;
    /**
     * 每页记录数
     */
    limit?: string;
    /**
     * 用户名，不区分大小写
     */
    name?: string;
    /**
     * 昵称，区分大小写
     */
    nickname?: string;
    /**
     * 分页
     */
    page?: string;
    /**
     * 报名时间查询截止
     */
    register_end?: string;
    /**
     * 报名时间查询起始
     */
    register_start?: string;
    /**
     * 用户 ID
     */
    uid?: string;

    /**
     * 报名时间时间区间
     */
    fe_register_date?: any[];
};

export type RegistrantInformationResult = {
    id: string;
    competition_id: string;
    user_id: string;
    nickname: string;
    username: string;
    info: {
        city: string;
        email: string;
        phone: string;
        gender: string;
        school: string;
        country: string;
        birthday: string;
        area_code: string;
        last_name: string;
        first_name: string;
    };
    created_at: string;
    deleted_at: string | null;
    updated_at: string;
};
