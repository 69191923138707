import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Card, Descriptions } from 'antd';
import { dateTimeFormate } from '@/utils';

/**
 * Types
 */
import { User } from '@/services/user';

type Props = {
    user: User | null;
};

const UserStatus: React.FC<Props> = ({ user }) => {
    const intl = useIntl();

    if (!user) {
        return null;
    }

    return (
        <Card style={{ marginBottom: 12 }}>
            <h3 style={{ marginBottom: 16 }}>
                <FormattedMessage id="p.uu.h.status" />
            </h3>
            <Descriptions
                layout="vertical"
                size="middle"
                items={[
                    {
                        key: '1',
                        label: '禁言状态',
                        children: !user?.is_muted ? (
                            <Badge color="green" text={intl.formatMessage({ id: 'p.uu.status.muted.normal' })} />
                        ) : (
                            <>
                                <Badge status="error" text={intl.formatMessage({ id: 'p.uu.status.muted.muted' })} />
                                {user?.time_unmute ? (
                                    <span style={{ marginLeft: 8 }}>
                                        （{intl.formatMessage({ id: 'p.uu.status.muted.unMutedAt' })}:
                                        {user?.time_unmute ? dateTimeFormate(user?.time_unmute) : ''}）
                                    </span>
                                ) : (
                                    <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                                )}
                            </>
                        ),
                    },
                    {
                        key: '2',
                        label: '封号状态',
                        children: !user?.is_banned ? (
                            <Badge color="green" text={intl.formatMessage({ id: 'p.uu.status.account.normal' })} />
                        ) : (
                            <>
                                <Badge
                                    status="error"
                                    text={intl.formatMessage({ id: 'p.uu.status.account.disabled' })}
                                />
                                {user?.time_unban ? (
                                    <span style={{ marginLeft: 8 }}>
                                        （{intl.formatMessage({ id: 'p.uu.status.account.enabledAt' })}:
                                        {user?.time_unban ? dateTimeFormate(user?.time_unban) : ''}）
                                    </span>
                                ) : (
                                    <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                                )}
                            </>
                        ),
                    },
                    {
                        key: '3',
                        label: '资金冻结状态',
                        children: !user?.is_funds_frozen ? (
                            <Badge color="green" text={intl.formatMessage({ id: 'p.uu.status.account.normal' })} />
                        ) : (
                            <>
                                <Badge status="error" text={<FormattedMessage id="p.uu.status.account.freeze" />} />
                                <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                            </>
                        ),
                    },
                    {
                        key: '4',
                        label: '禁播状态',
                        children: !user?.is_live_baned ? (
                            <Badge color="green" text={intl.formatMessage({ id: 'p.uu.status.account.normal' })} />
                        ) : (
                            <>
                                <Badge status="error" text={intl.formatMessage({ id: 'p.uu.action.liveDisable' })} />
                                {user?.time_live_unban ? (
                                    <span style={{ marginLeft: 8 }}>
                                        （{intl.formatMessage({ id: 'p.uu.status.account.enabledAt' })}:
                                        {user?.time_live_unban ? dateTimeFormate(user?.time_live_unban) : ''}）
                                    </span>
                                ) : (
                                    <span>（{intl.formatMessage({ id: 'p.uu.status.permanent' })}）</span>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </Card>
    );
};

export default UserStatus;
