import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import {
    App,
    Button,
    DatePicker,
    Flex,
    Form,
    Input,
    PaginationProps,
    Popconfirm,
    Select,
    Spin,
    Typography,
} from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy, trim } from 'lodash';
import dayjs from 'dayjs';

/**
 * APIs
 */
import { exportOrders, gOrders, pRefund } from '@/services/mobile-store';

/**
 * Components
 */
import Permission from '@/components/permission';
import SearchUser from '@/components/search-user';

/**
 * Constants
 */
const SkuOptions = [
    { label: 'SP001', value: 'SP001' },
    { label: 'SP002', value: 'SP002' },
];

const CountryOptions = [
    { label: <FormattedMessage id="country.Malaysia" />, value: '马来西亚' },
    { label: <FormattedMessage id="country.Indonesia" />, value: '印度尼西亚' },
    { label: <FormattedMessage id="country.SriLanka" />, value: '斯里兰卡' },
    { label: <FormattedMessage id="country.India" />, value: '印度' },
    { label: <FormattedMessage id="country.Pakistan" />, value: '巴基斯坦' },
    { label: <FormattedMessage id="country.Vietnam" />, value: '越南' },
    { label: <FormattedMessage id="country.Philippines" />, value: '菲律宾' },
    { label: <FormattedMessage id="country.Bangladesh" />, value: '孟加拉' },
    { label: <FormattedMessage id="country.Nigeria" />, value: '尼日利亚' },
    { label: <FormattedMessage id="country.Egypt" />, value: '埃及' },
];

const OrderStatusOptions = [
    { label: <FormattedMessage id="ms.s.paid" />, value: '3' },
    { label: <FormattedMessage id="ms.s.refunded" />, value: '6' },
];

// const OrderStatusMap = {
//     1: '已创建',
//     2: '待支付',
//     3: '已支付',
//     4: '已取消',
//     5: '已发货',
//     6: '已退款',
// };
const OrderStatusMap = {
    3: <FormattedMessage id="ms.s.paid" />,
    6: <FormattedMessage id="ms.s.refunded" />,
};

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import type { FormProps } from 'antd/lib';
import type { OrderResult, OrdersParams } from '@/services/mobile-store';

type State = {
    dataSource: OrderResult[];
    entity: OrderResult | null;
    openDrawer: boolean;
};

const PageMobileStoreOrders: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    const tableRef = useRef<ActionType>();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
    });
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<OrdersParams>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading: loadingApi,
        refresh: refreshApi,
        run: runApi,
    } = useRequest(gOrders, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setPagination({
                total: data.meta.total,
            });
            setState({
                dataSource: data.list,
            });
        },
    });

    // 导出
    const { loading: loadingExportApi, run: runExportApi } = useRequest(exportOrders, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            message.success(intl.formatMessage({ id: 'm.exportTips' }));
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<OrderResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'id',
                title: <FormattedMessage id="ms.t.c.orderId" />,
                width: 200,
            },
            {
                dataIndex: 'name',
                width: 200,
                title: <FormattedMessage id="t.c.user" />,
                render: (_, entity: any) => {
                    const { name = '-', nick_name = '-' } = entity;

                    return (
                        <>
                            <div>{nick_name}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'referral_code',
                title: <FormattedMessage id="ms.t.c.code" />,
                width: 120,
            },
            {
                dataIndex: 'inviter_uid',
                title: <FormattedMessage id="ms.t.c.parentUid" />,
                width: 200,
            },
            {
                dataIndex: 'sku',
                title: 'SKU',
                width: 120,
            },
            {
                dataIndex: 'spec',
                title: <FormattedMessage id="ms.t.c.specification" />,
                width: 120,
            },
            {
                dataIndex: 'unit_price',
                title: <FormattedMessage id="ms.t.c.unitPrice" />,
                width: 120,
            },
            {
                dataIndex: 'quantity',
                title: <FormattedMessage id="ms.t.c.quality" />,
                width: 120,
            },
            {
                dataIndex: 'total_price',
                title: <FormattedMessage id="ms.t.c.topPrice" />,
                width: 120,
            },
            {
                dataIndex: 'shipping_fee',
                title: <FormattedMessage id="ms.t.c.shippingFee" />,
                width: 120,
            },
            {
                dataIndex: 'actual_payment',
                title: <FormattedMessage id="ms.t.c.paidAmount" />,
                width: 120,
            },
            {
                dataIndex: 'country',
                title: <FormattedMessage id="ms.t.c.countryRegion" />,
                width: 120,
            },
            {
                dataIndex: 'address',
                title: <FormattedMessage id="ms.t.c.address" />,
            },
            {
                dataIndex: 'zip_code',
                title: <FormattedMessage id="ms.t.c.postalCode" />,
                width: 120,
            },
            {
                dataIndex: 'order_status',
                title: <FormattedMessage id="ms.t.c.orderStatus" />,
                valueEnum: OrderStatusMap,
                width: 120,
            },
            {
                dataIndex: 'receiver',
                title: <FormattedMessage id="ms.t.c.recipient" />,
                width: 120,
            },
            {
                dataIndex: 'payment_time',
                title: <FormattedMessage id="ms.t.c.paidAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    entity.order_status === 3 && (
                        <Permission permission="Mobile-Store_Orders_Refund" key="refund">
                            <Spin spinning={!!tableCellViolateLoading[entity.id]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellViolateLoading({
                                            [entity.id]: true,
                                        });
                                        pRefund({
                                            order_id: entity.id,
                                            user_id: entity.user_id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refreshApi();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellViolateLoading({
                                                    [entity.id]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellViolateLoading({
                                                    [entity.id]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link>
                                        <FormattedMessage id="ms.b.refund" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingApi,
        options: {
            reload: refreshApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 2770,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
    };

    /**
     * FormProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            for (const key in values) {
                if (typeof values[key] === 'string') {
                    values[key] = trim(values[key]);
                }
            }

            if (values.fe_date && values.fe_date.length === 2) {
                values.payment_time_start = values.fe_date[0].startOf('day').format();
                values.payment_time_end = values.fe_date[1].endOf('day').format();
            }
            values.fe_date = undefined;

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runApi({
            page: pagination.current as any,
            limit: pagination.pageSize as any,
            ...payload,
        });
    }, [payload, pagination.current, pagination.pageSize]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 'ms.t.c.orderId' })} allowClear />
                        </Form.Item>

                        <SearchUser form={formSearch} />

                        <Form.Item name="referral_code" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 'ms.t.c.code' })} allowClear />
                        </Form.Item>

                        <Form.Item name="inviter_uid" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 'ms.t.c.parentUid' })} allowClear />
                        </Form.Item>

                        <Form.Item name="sku" style={{ marginRight: 0 }}>
                            <Select options={SkuOptions} placeholder="SKU" allowClear style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="country" style={{ marginRight: 0 }}>
                            <Select
                                options={CountryOptions}
                                placeholder={intl.formatMessage({ id: 'ms.t.c.countryRegion' })}
                                allowClear
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="order_status" style={{ marginRight: 0 }}>
                            <Select
                                options={OrderStatusOptions}
                                placeholder={intl.formatMessage({ id: 'ms.t.c.paymentStatus' })}
                                allowClear
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={intl.formatMessage({ id: 'ms.t.c.paidAt' })}
                            name="fe_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker
                                allowClear={true}
                                disabledDate={(current) => {
                                    // 禁止选择未来的日期
                                    return current && current > dayjs().endOf('day');
                                }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Mobile-Store_Orders_Export">
                            <Button
                                loading={loadingExportApi}
                                onClick={async () => {
                                    runExportApi({ ...payload });
                                }}
                            >
                                <FormattedMessage id="b.export" />
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageMobileStoreOrders;
