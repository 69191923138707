import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Flex, Form, Input, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy, trim } from 'lodash';
import dayjs from 'dayjs';

/**
 * Components
 */
import TableCellMedia from '@/components/table-cell/table-cell-media';
import ComponentTableCellDebate from '@/pages/content-management/posts/components/table-cell-debate';
import Permission from '@/components/permission';
import ComponentAddDrawer from './components/add-drawer';
import { Pagination, PaginationProps } from '@repo/ui/pagination';

/**
 * APIs
 */
import { changeLivesRecommendations, gLives } from '@/services/live';

/**
 * Types
 */
import type { FormProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { GetLiveParams, LiveRoomParams, LiveRoomResult, LivesResult } from '@/services/live';

/**
 * Constants
 */
const liveStatusMap = {
    pending: <FormattedMessage id="cl.c.noStart" />,
    live: <FormattedMessage id="cl.c.liveing" />,
    end: <FormattedMessage id="cl.c.finished" />,
};

const liveOptions = Object.entries(liveStatusMap).map((v) => {
    return {
        label: v[1],
        value: v[0],
    };
});

/**
 * State
 */
type State = {
    dataSource: LiveRoomResult[];
    openAddDrawer: boolean;
};

const PageClientLive: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        openAddDrawer: false,
    });

    const [tableCellDeleteLoading, setTableCellDeleteLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        hasMore: false,
        searchAfter: '',
        size: 20,
        total: 0,
        onChange: ({ page, searchAfter, size }) => {
            setPagination({
                current: page,
                size: size,
                searchAfter,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<LiveRoomParams>({});

    const {
        loading: loadingLive,
        refresh: refreshLive,
        run: runLive,
    } = useRequest(gLives, {
        manual: true,
        onSuccess: ({ data: { code, data, msg, meta } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.map((v) => {
                    const log_operation = get(v, 'log_operation', [])
                        .reverse()
                        .find((v: any) => {
                            return v.after.is_recommended;
                        });
                    if (log_operation) {
                        v.operator_account_name = log_operation.operator.name;
                        v.operator_at = log_operation.created_at;
                    }
                    return v;
                }),
            });

            setPagination({
                size: meta.size,
                total: meta.total,
                hasMore: meta.has_more,
                searchAfter: meta.search_after || '',
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<GetLiveParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.id) {
                values.id = trim(values.id);
            }
            if (values.user_id) {
                values.user_id = trim(values.user_id);
            }

            if (values.debate_fuzzy) {
                values.debate_fuzzy = trim(values.debate_fuzzy);
            }

            setPagination({
                current: 1,
                searchAfter: '',
            });

            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<LivesResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="cl.t.c.homeId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="cl.t.c.createrId" />,
                width: 200,
            },
            {
                dataIndex: ['debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="ctp.t.c.topic" />,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity) => (
                    <ComponentTableCellDebate
                        forTxt={get(entity, 'debate.for_text', null) as any}
                        against={get(entity, 'debate.against_text', null) as any}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_imageAndVideo',
                title: <FormattedMessage id="cad.t.c.cover" />,
                render: (_, entity) => {
                    const coverUrl = get(entity, 'cover', '');
                    if (!coverUrl) {
                        return '-';
                    }
                    return <TableCellMedia list={[coverUrl]} />;
                },
                width: 200,
            },
            {
                dataIndex: 'start_at',
                title: <FormattedMessage id="cl.t.c.startAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'end_at',
                title: <FormattedMessage id="cl.t.c.endAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'type_competition',
                title: <FormattedMessage id="cl.t.c.liveMode" />,
                render: (text) => {
                    if (!text) {
                        return '-';
                    }
                    return <span>{`${text}v${text}`}</span>;
                },
                width: 120,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="cl.t.c.liveStatus" />,
                valueEnum: liveStatusMap,
                width: 120,
                render: (_, entity) => {
                    //当前时间 小于开始时间为未开始, 大于结束时间为已结束, 其他为直播中
                    const { start_at, end_at } = entity;
                    if (!start_at || !end_at) {
                        return '-';
                    }
                    const now = dayjs();
                    if (now.isAfter(end_at)) {
                        return liveStatusMap.end;
                    }
                    if (now.isBefore(start_at)) {
                        return liveStatusMap.pending;
                    }
                    return liveStatusMap.live;
                },
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.type" />,
                render: () => '语音',
                width: 120,
            },
            {
                dataIndex: 'operator_account_name',
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'operator_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Content_Live_Delete" key="delete">
                        <Spin spinning={!!tableCellDeleteLoading[entity.id]} size="small">
                            <Popconfirm
                                title={intl.formatMessage({ id: 'c.areyousure' })}
                                onConfirm={() => {
                                    setTableCellDeleteLoading({
                                        [entity.id]: true,
                                    });
                                    changeLivesRecommendations(entity.id, {
                                        is_recommended: false,
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                refreshLive();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellDeleteLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellDeleteLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <Typography.Link type="danger">
                                    <FormattedMessage id="b.delete" />
                                </Typography.Link>
                            </Popconfirm>
                        </Spin>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingLive,
        options: {
            reload: refreshLive,
        },
        pagination: false,
        rowKey: 'id',
        scroll: {
            x: 2510,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
        toolBarRender: () => [
            <Button
                type="primary"
                onClick={() => {
                    setState({ openAddDrawer: true });
                }}
                key="sureSort"
            >
                <FormattedMessage id="b.add" />
            </Button>,
        ],
    };

    /**
     * Effects
     */
    useEffect(() => {
        runLive({
            ...payload,
            size: `${pagination.size}`,
            search_after: pagination.searchAfter,
            'sort[0][created_at]': 'desc',
            is_recommended: 'true',
        });
    }, [pagination.current, pagination.size, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cl.t.c.homeId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cl.t.c.createrId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate_fuzzy" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'ctp.t.c.topic' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={liveOptions}
                                placeholder={<FormattedMessage id="cl.t.c.liveStatus" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingLive}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Pagination {...pagination} isLoading={loadingLive} />

            <ComponentAddDrawer
                open={state.openAddDrawer}
                closeCallback={(reload?: boolean) => {
                    setState({ openAddDrawer: false });
                    reload && refreshLive();
                }}
            />
        </>
    );
};

export default PageClientLive;
