import { useState } from 'react';
import { useBoolean } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Form, Input, Segmented, Space } from 'antd';

/**
 * APIs
 */
import { errorCodeMsgMap } from '@/services/task-config';
import { cPresetQuestion, pPresetQuestion } from '@/services/preset-question';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';
// form submit
const formSubmit = async (
    propsData: PresetQuestionResult | null,
    formData: CreatePresetQuestionParams,
    type: 'Home' | 'More',
) => {
    const submitFormData: CreatePresetQuestionParams = {
        languages: formData.languages,
    };
    // 区分是更新还是新增
    if (propsData && propsData.id) {
        return await pPresetQuestion(propsData.id, submitFormData);
    }
    submitFormData.type = type === 'Home' ? 0 : 1;
    return await cPresetQuestion(submitFormData);
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { CreatePresetQuestionParams, Languages, PresetQuestionResult } from '@/services/preset-question';

type LanguageType = (typeof Language)[number];

type Props = {
    open: boolean;
    entity: PresetQuestionResult | null;
    type: 'Home' | 'More';
    closeModelForm: (reload?: boolean) => void;
};

/**
 * Constants
 */
const Language = ['EN', 'CHS', 'CHT', 'JPN', 'VIE', 'TRK', 'ESP', 'PT'];

const ComponentModalFormCustom: React.FC<Props> = ({ open, entity, type, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    const [showLanguage, setShowLanguage] = useState('EN');

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const res = await formSubmit(entity, formData, type);
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, errorCodeMsgMap, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch {
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        size: 'large',
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id={entity ? 'b.edit' : 'b.add'} />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                setShowLanguage('EN');
                form.resetFields();
            } else if (entity) {
                console.log('entity', entity);
                form.setFieldsValue({
                    languages: {
                        ...entity.languages,
                    },
                });
            } else {
                form.setFieldsValue({});
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<Languages> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Form.Item
                    name="_language"
                    label={intl.formatMessage({ id: 'tc.f.language' })}
                    rules={[{ required: true }]}
                    initialValue={'EN'}
                >
                    <Segmented<LanguageType>
                        options={Language}
                        value={showLanguage}
                        onChange={(value) => {
                            setShowLanguage(value);
                            form.setFieldValue('_language', value);
                        }}
                    />
                </Form.Item>
                {Language.map((v) => {
                    return (
                        <div key={v} style={{ display: showLanguage === v ? 'block' : 'none' }}>
                            <Form.Item
                                name={['languages', v, 'question']}
                                label={intl.formatMessage({ id: 'pq.t.c.question' }) + `（${v}）`}
                                rules={[{ required: 'EN' === v }]}
                                initialValue=""
                            >
                                <Input showCount maxLength={200} />
                            </Form.Item>

                            <Form.Item
                                name={['languages', v, 'answer']}
                                label={intl.formatMessage({ id: 'pq.t.c.answer' }) + `（${v}）`}
                                rules={[{ required: 'EN' === v }]}
                                initialValue=""
                            >
                                <Input.TextArea autoSize={{ minRows: 10, maxRows: 20 }} showCount maxLength={2000} />
                            </Form.Item>
                        </div>
                    );
                })}
            </Form>
        </Drawer>
    );
};

export default ComponentModalFormCustom;
