export default {
    pq: {
        t: {
            c: {
                qid: '问题ID',
                question: '问题',
                answer: '回答',
            },
        },
    },
};
