const tokenIconMap: Record<string, string> = {
    token_soc: '/token_icon/soc.png',
    MEME_H0N9M6PP6V4: '/token_icon/clone.png',
    token_usdt: '/token_icon/usdt.png',
    token_plato: '/token_icon/plato.png',
    token_usdt: '/token_icon/usdt.png',
};

// 激励计划 token
export const IncentiveSeasonsTokenMap: Record<string, string> = {
    token_soc: 'SOC',
    MEME_H0N9M6PP6V4: 'CLONE',
    token_plato: 'PLATO',
};

export const IncentiveSeasonsTokenOptions = Object.entries(IncentiveSeasonsTokenMap).map(([token_id, label]) => ({
    label,
    token_id,
    url: tokenIconMap[token_id],
}));

// 大使激励计划 token
export const AmbIncentiveSeasonsTokenMap: Record<string, string> = {
    token_soc: 'SOC',
    MEME_H0N9M6PP6V4: 'CLONE',
    token_usdt: 'USDT',
};

export const AmbIncentiveSeasonsTokenOptions = Object.entries(AmbIncentiveSeasonsTokenMap).map(([token_id, label]) => ({
    label,
    token_id,
    url: tokenIconMap[token_id],
}));
