/**
 * Permission
 */
export default {
    pm: {
        Add: 'Add',
        Edit: 'Edit',
        Delete: 'Delete',
        Export: 'Export',
        Configure: 'Configure',
        Users: 'Users',
        Users_Users: 'Users',
        Users_Usernames: 'Usernames',
        Users_Usernames_Add: 'Add',
        Users_Usernames_Import: 'Import',
        Users_Usernames_Delete: 'Delete',
        'Users_Usernames_Bulk-Delete': 'Bulk Delete',
        Users_Users_Details: 'Detail',
        Users_Users_Details_Mute: 'Mute',
        Users_Users_Details_Disable: 'Disable',
        Users_Users_Details_Unmute: 'Unmute',
        Users_Users_Details_Enable: 'Enable',
        'Users_Identity-Verification-History': 'Identity Verification History',
        'Users_Identity-Verification-History_Export': 'Export',
        'Users_Users_Details_Manage-Profile': 'Manage Profile',
        'Users_Users_Details_Freeze-Funds': 'Freeze Funds',
        'Users_Users_Details_Unfreeze-Funds': 'Unfreeze Funds',
        'Users_Users_Details_Disable-Live': 'Disable Live',
        'Users_Users_Details_Enable-Live': 'Enable Live',
        Users_Users_Export: 'Export',
        'Users_Sanction-History': 'Sanction History',
        Users_Celebrities: 'Celebrities',
        Users_Celebrities_Add: 'Add',
        Users_Celebrities_Delete: 'Delete',
        Users_Celebrities_Export: 'Export',
        Content: 'Content',
        Content_Posts: 'Posts',
        Content_Posts_Delete: 'Delete',
        'Content_Posts-Comments': 'Posts Comments',
        'Content_Posts-Comments_Delete': 'Delete',
        Content_Shorts: 'Shorts',
        Content_Shorts_Delete: 'Delete',
        'Content_Shorts-Comments': 'Shorts Comments',
        'Content_Shorts-Comments_Delete': 'Delete',
        Content_Live: 'Live',
        Content_Live_End: 'End',
        Content_Live_Delete: 'Delete',
        Ambassadors: 'Ambassadors',
        Ambassadors_Applications: 'Applications',
        'Ambassadors_Applications_Public-Pool': 'Public Pool',
        'Ambassadors_Applications_Public-Pool_Assign': 'Assign',
        'Ambassadors_Applications_Private-Pool': 'Private Pool',
        'Ambassadors_Applications_Private-Pool_All-Data': 'All Data',
        'Ambassadors_Applications_Private-Pool_Personal-Data': 'Personal Data',
        'Ambassadors_Applications_Review-Records': 'Review Records',
        Ambassadors_Ambassadors: 'Ambassadors Management',
        'Ambassadors_Ambassadors_Public-Pool': 'Public Pool',
        'Ambassadors_Ambassadors_Public-Pool_Assign': 'Assign',
        Ambassadors_Ambassadors_Ambassadors: 'Ambassadors',
        Ambassadors_Ambassadors_Ambassadors_Assign: 'Assign',
        'Ambassadors_Ambassadors_Ambassadors_All-Data': 'All Data',
        'Ambassadors_Ambassadors_Ambassadors_Personal-Data': 'Personal Data',
        Ambassadors_Data: 'Data',
        'Ambassadors_Data_Ambassador-Data': 'Ambassador Data',
        'Ambassadors_Data_Ambassador-Data_Export': 'Export',
        Ambassadors_Configurations: 'Configurations',
        'Ambassadors_Configurations_Mkt-Member-Config': 'Mkt Member Config',
        'Ambassadors_Configurations_Mkt-Member-Config_Group': 'Group',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Add': 'Add',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Edit': 'Edit',
        'Ambassadors_Configurations_Mkt-Member-Config_Group_Sort': 'Sort',
        'Ambassadors_Configurations_Mkt-Member-Config_Member': 'Member',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Add': 'Add',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Edit': 'Edit',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Transfer': 'Transfer',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Sort': 'Sort',
        'Ambassadors_Configurations_Mkt-Member-Config_Member_Delete': 'Delete',
        'Ambassadors_Configurations_Incentive-Seasons': 'Incentive Seasons',
        Incentives: 'Incentives',
        'Incentives_Incentive-Seasons': 'Incentive Seasons',
        'Incentives_Incentive-Seasons_Add': 'Add',
        'Incentives_Incentive-Seasons_Edit': 'Edit',
        'Incentives_Incentive-Seasons_Delete': 'Delete',
        'Incentives_Task-Config_Add': 'Add',
        'Incentives_Task-Config_Edit': 'Edit',
        'Incentives_Task-Config_Delete': 'Delete',
        'Incentives_Task-Config_Sort': 'Sort',
        Debate_Championship: 'Debate Championship',
        'Debate_Championship-Registrant_Information': 'Registrant Information',
        Clone: 'Clone',
        'Clone_Preset-Questions': 'Preset Questions',
        'Super-Users': 'Super Users',
        'Super-Users_Behavior': 'Behavior',
        'Super-Users_Behavior_Edit': 'Edit',
        'Super-Users_Benefit': 'Benefit',
        'Super-Users_Benefit_Add': 'Add',
        'Super-Users_Benefit_Edit': 'Edit',
        'Super-Users_Benefit_Delete': 'Delete',
        Creator_Center: 'Creator Center',
        'Creator_Center-Topic': 'Topic',
        'Creator_Center-Creator_Hub': 'Creator Hub',
        'Creator_Center-Top_Posts': 'Top Posts',
        'Client-Config': 'Client Config',
        'Client-Config_Plaza-Config': 'Plaza Config',
        'Client-Config_Super-Users': 'Super Users',
        'Client-Config_Super-Users_Add': 'Add',
        'Client-Config_Super-Users_Edit': 'Edit',
        'Client-Config_Super-Users_Delete': 'Delete',
        'Client-Config_Airdrop-Whitelist': 'Airdrop Whitelist',
        'Client-Config_Airdrop-Whitelist_Add': 'Add',
        'Client-Config_Airdrop-Whitelist_Edit': 'Edit',
        'Client-Config_Airdrop-Whitelist_Delete': 'Delete',
        'Client-Config_Domain': 'Domain',
        'Client-Config_App-Version': 'App Version',
        'Client-Config_Channel-Code': 'Channel Code',
        'Client-Config_Live-Recommendations': 'Live Recommendations',
        'Client-Config_Live-Recommendations_Add': 'Add',
        'Client-Config_Live-Recommendations_Delete': 'Delete',
        IM_Messages: 'Message',
        'Mobile-Store': 'Mobile Store',
        'Mobile-Store_Orders': 'Orders',
        'Mobile-Store_Orders_Refund': 'Orders Refund',
        'Mobile-Store_Refunds': 'Refunds',
        'Mobile-Store_Inventory': 'Inventory',
        'Mobile-Store_Order-Config': 'Order Config',
        'Mobile-Store_Order-Config_Configure': 'Configure',
        Marketplace: 'Marketplace',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT Collection',
        Marketplace_NFT_NFT: 'NFT',
        'Marketplace_NFT_NFT-Marketplace': 'NFT Marketplace',
        Marketplace_Token: 'Token',
        'Marketplace_Token_Token-Config': 'Token Config',
        'Marketplace_Token_Token-Config_Edit': 'Edit',
        'Marketplace_Token_Token-Config_Sort': 'Sort',
        Marketplace_Token_Pool: 'Token Pool',
        Marketplace_Token_Pool_Create: 'Add',
        Marketplace_Token_Pool_Edit: 'Edit',
        'Marketplace_Token_Top-Token': 'Top Token',
        'Marketplace_Token_Top-Token_Add': 'Add',
        'Marketplace_Token_Top-Token_Sort': 'Sort',
        'Marketplace_Token_Top-Token_Delete': 'Delete',
        Marketplace_Meme_Memecoin_Delist: 'Delist',
        Airdrop: 'Airdrop',
        'Airdrop_Airdrop-Seasons': 'Airdrop Seasons',
        'Airdrop_Airdrop-Seasons_Create': 'Create',
        'Airdrop_Airdrop-Seasons_Edit': 'Edit',
        'Airdrop_Airdrop-Seasons_Delete': 'Delete',
        'Airdrop_Airdrop-Seasons_Distribute': 'Distribute',
        'Airdrop_Airdrop-Config': 'Airdrop Config',
        'Airdrop_Airdrop-Config_Edit': 'Edit',
        'System-Management': 'System Management',
        'System-Management_Accounts': 'Accounts',
        'System-Management_Accounts_Create': 'Create',
        'System-Management_Accounts_Edit': 'Edit',
        'System-Management_Accounts_Delete': 'Delete',
        'System-Management_Roles': 'Roles',
        'System-Management_Roles_Create': 'Create',
        'System-Management_Roles_Edit': 'Edit',
        'System-Management_Roles_Delete': 'Delete',
        'System-Management_Permissions': 'Permissions',
        'System-Management_Logs': 'Logs',
        'System-Management_Exports': 'Downloads',
        'System-Management_Exports_Exports': 'Downloads',
        'System-Management_Exports_Exports_All-Data': 'All Data',
        'System-Management_Exports_Exports_Personal-Data': 'Personal Data',
    },
};
