export default {
    cl: {
        b: {
            closeLive: 'End',
        },
        t: {
            c: {
                createrId: 'Creator UID',
                homeId: 'Room ID',
                endAt: 'End At',
                liveMode: 'Format',
                liveStatus: 'Live Status',
                startAt: 'Start At',
            },
        },
        c: {
            noStart: 'Pending',
            liveing: 'Live',
            finished: 'End',
        },
        s: {
            audio: 'Audio',
        },
    },
};
