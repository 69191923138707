import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Card, Flex, message } from 'antd';
import { useRequest, useSetState } from 'ahooks';
import { useSearchParams } from 'react-router-dom';

/**
 * Components
 */
import { FormattedMessage } from 'react-intl';
import Permission, { useAccess } from '@/components/permission';
import UserStatus from './components/user-status';
import UserDetail from './components/user-detail';
import UserStatusModalForm from './components/user-status-modal-form';
import ComponentManageProfileDrawer from './components/manage-profile-drawer';

/**
 * Api
 */
import { userDetailApi } from '@/services/user';

/**
 * Types
 */
import type { User } from '@/services/user';
import type { ModalFormType } from './components/user-status-modal-form';

type State = {
    openManageProfile: boolean;
};

const Users: React.FC = () => {
    /**
     * Hooks
     */

    /**
     * States
     */
    const [user, setUser] = useState<User | null>(null);
    const [type, setType] = useState<ModalFormType>(null);
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');

    const [state, setState] = useSetState<State>({
        openManageProfile: false,
    });

    const checkAccess = useAccess();

    const isManageProfile = checkAccess('Users_Users_Details_Manage-Profile');
    const isMute = checkAccess('Users_Users_Details_Mute');
    const isBan = checkAccess('Users_Users_Details_Disable');
    const isUnmute = checkAccess('Users_Users_Details_Unmute');
    const isEnable = checkAccess('Users_Users_Details_Enable');
    const isFreezeFunds = checkAccess('Users_Users_Details_Freeze-Funds');
    const isUnfreezeFunds = checkAccess('Users_Users_Details_Unfreeze-Funds');
    // 直播 解|禁 播
    const isDisableLive = checkAccess('Users_Users_Details_Disable-Live');
    const isEnableLive = checkAccess('Users_Users_Details_Enable-Live');

    const hasMutePermission = isMute || isUnmute;
    const hasBanPermission = isBan || isEnable;
    const hasFreezeFoundsPermission = isFreezeFunds || isUnfreezeFunds;
    // 直播 解|禁 播
    const hasLivePermission = isDisableLive || isEnableLive;

    /**
     * Effects
     */
    const { run: fetchUserDetail, refresh: refreshUserDetail } = useRequest(userDetailApi, {
        manual: true,
        onSuccess: (res) => {
            if (get(res, 'data.code') === 0) {
                // res.data.data.is_live_baned = true;
                // res.data.data.enable_live_at = 1742960622;
                setUser(res?.data?.data);
            } else {
                message.error(res?.data?.msg);
                setUser(null);
            }
        },
    });

    useEffect(() => {
        if (uid) {
            fetchUserDetail(uid);
        }
    }, [uid]);

    return (
        <>
            {(hasMutePermission ||
                hasBanPermission ||
                isManageProfile ||
                hasFreezeFoundsPermission ||
                hasLivePermission) && (
                <Card style={{ marginBottom: 12 }}>
                    <Flex gap={12}>
                        <Permission permission="Users_Users_Details_Manage-Profile">
                            <Button
                                onClick={() => {
                                    setState({
                                        openManageProfile: true,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            >
                                <FormattedMessage id="p.uu.btn.mangProfile" />
                            </Button>
                        </Permission>
                        {!user?.is_muted ? (
                            <Permission permission="Users_Users_Details_Mute">
                                <Button
                                    onClick={() => {
                                        setType('mute');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.mute" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Unmute">
                                <Button
                                    onClick={() => {
                                        setType('mute');
                                        setOpen(true);
                                    }}
                                    color="danger"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.unmute" />
                                </Button>
                            </Permission>
                        )}

                        {!user?.is_banned ? (
                            <Permission permission="Users_Users_Details_Disable">
                                <Button
                                    onClick={() => {
                                        setType('ban');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.disable" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Enable">
                                <Button
                                    onClick={() => {
                                        setType('ban');
                                        setOpen(true);
                                    }}
                                    color="danger"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.enable" />
                                </Button>
                            </Permission>
                        )}
                        {/* 资金冻结 */}
                        {!user?.is_funds_frozen ? (
                            <Permission permission="Users_Users_Details_Freeze-Funds">
                                <Button
                                    onClick={() => {
                                        setType('freeze');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.freezeFunds" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Unfreeze-Funds">
                                <Button
                                    onClick={() => {
                                        setType('freeze');
                                        setOpen(true);
                                    }}
                                    danger
                                    variant="outlined"
                                >
                                    <FormattedMessage id="p.uu.btn.unfreezeFunds" />
                                </Button>
                            </Permission>
                        )}
                        {/* 直播 解|禁 播 */}
                        {!user?.is_live_baned ? (
                            <Permission permission="Users_Users_Details_Disable-Live">
                                <Button
                                    onClick={() => {
                                        setType('liveDisable');
                                        setOpen(true);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="pm.Users_Users_Details_Disable-Live" />
                                </Button>
                            </Permission>
                        ) : (
                            <Permission permission="Users_Users_Details_Enable-Live">
                                <Button
                                    onClick={() => {
                                        setType('liveDisable');
                                        setOpen(true);
                                    }}
                                    danger
                                    variant="outlined"
                                >
                                    <FormattedMessage id="pm.Users_Users_Details_Enable-Live" />
                                </Button>
                            </Permission>
                        )}
                    </Flex>
                </Card>
            )}

            <UserDetail user={user} />

            <UserStatus user={user} />

            <UserStatusModalForm
                type={type}
                state={{ open, user }}
                onClose={(refresh?: boolean) => {
                    setType(null);
                    setOpen(false);
                    if (refresh) {
                        setTimeout(() => {
                            refreshUserDetail();
                        }, 1000);
                    }
                }}
            />

            <ComponentManageProfileDrawer
                open={state.openManageProfile}
                user={user}
                closeModelForm={(reload) => {
                    setState({ openManageProfile: false });
                    if (reload) {
                        setTimeout(() => {
                            refreshUserDetail();
                        }, 1000);
                    }
                }}
            />
        </>
    );
};

export default Users;
