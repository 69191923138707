import { AreaChartOutlined, CommentOutlined, HomeOutlined, SettingOutlined, ShopOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
/* 首页 Home */
import PageDashboard from '@/pages/dashboard';

/* 用户管理 Users */
import PageIdentityVerificationHistory from '@/pages/users/identity-verification-history';
// 名人管理
import PageCelebrities from '@/pages/users/celebrities';

/* 内容管理 Content */
// 帖子管理
import PageContentPosts from '@/pages/content-management/posts';
import PageContentPostsReplies from '@/pages/content-management/posts-replies';
import PageContentShorts from '@/pages/content-management/shorts';
import PageContentShortsReplies from '@/pages/content-management/shorts-replies';
import PageContentLive from '@/pages/content-management/live';

/* 创作者中心 Creator */
// 话题管理
import PageCreatorTopics from '@/pages/creator/topic';
// 创作学院
import PageCreatorAcademy from '@/pages/creator/academy';
// 推荐帖子
import PageCreatorPosts from '@/pages/creator/posts';

/* 大使计划 Ambassadors */
// 大使申请
import PageAmbassadorsApplicationsPublicPool from '@/pages/ambassadors/applications/public-pool';
import PageAmbassadorsApplicationsPrivatePool from '@/pages/ambassadors/applications/private-pool';
import PageAmbassadorsApplicationsReviewRecords from '@/pages/ambassadors/applications/review-records';
// 大使管理
import PageAmbassadorsAmbassadorsPublicPool from '@/pages/ambassadors/ambassadors/public-pool';
import PageAmbassadorsAmbassadorsAmbassadors from '@/pages/ambassadors/ambassadors/ambassadors';
// 数据管理
import PageAmbassadorsDataAmbassadorData from '@/pages/ambassadors/data/ambassador-data';
// 配置管理
// 运营人员管理
import PageAmbassadorsConfigurationsMktMemberConfig from '@/pages/ambassadors/configurations/mkt-member-config';

/* 激励计划 Incentives */
// 激励列表
import PageIncentiveSeasons from '@/pages/incentives/incentive-seasons';
// 任务管理
import PageTaskConfig from '@/pages/incentives/task-config';

/* 超级账号 Super users */
import PageSuperUsersBehavior from '@/pages/super-users/behavior';
import PageSuperUsersBenifit from '@/pages/super-users/benefit';

/* 客户端配置 Client Config */
// 数字文明广场
import PageClientConfigPlazaConfig from '@/pages/client/plaza-config';
import PageClientLive from '@/pages/client/live-recommendations';

/** Debate Championship 辩论大赛 */
// 报名信息
import PageDebateRegistrantInfomation from '@/pages/debate-championship/registrant-information';

/** Clone */
// 预设问题
import PageClonePresetQuestions from '@/pages/clone/preset-questions';

// 超级账号
import PagePostAirdropRootAccount from '@/pages/client/post-airdrop-root-account';
// 域名管理
import PageClientDomain from '@/pages/client/domain';
// App版本管理
import PageClientAppVersion from '@/pages/client/app-version';
// 渠道码管理
import PageClientChannelCode from '@/pages/client/channel-code';

/* 空头管理 Airdrop */
// 官方空投活动列表
import PageOfficialAirdropList from '@/pages/airdrop-management/official-airdrop-list';
// 用户管理
import UserNameManagement from '@/pages/username-management';

/* IM */
//站内通知
import PageIMMessage from '@/pages/im/messages';

/* Mobile Store 手机商城 */
// 订单管理
import PageMobileStoreOrders from '@/pages/mobile-store/orders';
// 退款
import PageMobileStoreRefunds from '@/pages/mobile-store/refunds';
// 库存
import PageMobileStoreInventory from '@/pages/mobile-store/inventory';
// 订单配置
import PageMobileStoreOrderConfig from '@/pages/mobile-store/order-config';

// 交易市场
import PageMarketplaceCollections from '@/pages/marketplace/nft/collections';
import PageMarketplaceNft from '@/pages/marketplace/nft/nft';
import PageMarketplaceNftMarketplace from '@/pages/marketplace/nft/marketplace';

// 空投管理 - 官方空投参数配置
// 官方空投参数配置
import PageOfficialAirdropConfig from '@/pages/airdrop-management/official-airdrop-config';
// 用户管理
import PageUsers from '@/pages/users/users';
import PageUsersDetail from '@/pages/users/user-detail';
import PageSanctionHistory from '@/pages/users/sanction-history';

/* 系统管理 System Management */
// 账号管理
import PageAccountManagement from '@/pages/system-management/account-management';
// 角色管理
import PageRoleManagement from '@/pages/system-management/role-management';
// 系统日志
import PageLogs from '@/pages/system-management/logs';
// 导出管理
import PageExports from '@/pages/system-management/exports';

/* 交易市场 Marketplace */
// 代币配置
import PageTokenConfig from '@/pages/marketplace/token/token-config';
// 流动性池
import PageTokenPool from '@/pages/marketplace/token/token-pool';
// 热门代币配置
import PageTokenTopToken from '@/pages/marketplace/token/top-token';
// 交易市场-Meme-Memecoin
import PageNFTMarketplaceMemecoin from '@/pages/marketplace/meme/memecoin';

/**
 * Types
 */
import type { MenuDataItem } from '@ant-design/pro-components';
import PageAmbassadorsIncentiveSeasons from '@/pages/ambassadors/configurations/incentive-seasons';

const routes = [
    /* 首页 Home */
    {
        element: <PageDashboard />,
        icon: <HomeOutlined />,
        name: (<FormattedMessage id="m.Home" />) as unknown as string,
        path: '/',
    },
    /* 用户管理 Users */
    {
        children: [
            {
                access: 'Users_Users',
                element: <PageUsers />,
                name: <FormattedMessage id="m.Users_Users" />,
                path: 'users',
            },
            {
                access: 'Users_Users_Details',
                element: <PageUsersDetail />,
                name: <FormattedMessage id="m.Users_Users_Details" />,
                hideInMenu: true,
                path: 'detail',
            },
            {
                access: 'Users_Identity-Verification-History',
                element: <PageIdentityVerificationHistory />,
                name: <FormattedMessage id="m.Users_Identity-Verification-History" />,
                path: 'identity-verification-history',
            },
            {
                access: 'Users_Sanction-History',
                element: <PageSanctionHistory />,
                name: <FormattedMessage id="m.Users_Sanction-History" />,
                path: 'sanction-history',
            },
            {
                access: 'Users_Usernames',
                element: <UserNameManagement />,
                name: <FormattedMessage id="m.Users_Usernames" />,
                path: 'usernames',
            },
            {
                access: 'Users_Celebrities',
                element: <PageCelebrities />,
                name: <FormattedMessage id="m.Users_Celebrities" />,
                path: 'celebrities',
            },
        ],
        access: 'Users',
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Users" />,
        path: 'users',
    },
    /* 内容管理 Content */
    {
        children: [
            {
                access: 'Content_Posts',
                element: <PageContentPosts />,
                name: <FormattedMessage id="m.Content_Posts" />,
                path: 'posts',
            },
            {
                access: 'Content_Posts-Comments',
                element: <PageContentPostsReplies />,
                name: <FormattedMessage id="m.Content_Posts-Comments" />,
                path: 'posts-comments',
            },
            {
                access: 'Content_Shorts',
                element: <PageContentShorts />,
                name: <FormattedMessage id="m.Content_Shorts" />,
                path: 'shorts',
            },
            {
                access: 'Content_Shorts-Comments',
                element: <PageContentShortsReplies />,
                name: <FormattedMessage id="m.Content_Shorts-Comments" />,
                path: 'shorts-comments',
            },
            {
                access: 'Content_Live',
                element: <PageContentLive />,
                name: <FormattedMessage id="m.Content_Live" />,
                path: 'live',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Content" />,
        access: 'Content',
        path: 'content',
    },
    /* 大使计划 Ambassadors */
    {
        children: [
            {
                children: [
                    {
                        access: 'Ambassadors_Applications_Public-Pool',
                        element: <PageAmbassadorsApplicationsPublicPool />,
                        name: <FormattedMessage id="m.Ambassadors_Applications_Public-Pool" />,
                        path: 'public-pool',
                    },
                    {
                        access: 'Ambassadors_Applications_Private-Pool',
                        element: <PageAmbassadorsApplicationsPrivatePool />,
                        name: <FormattedMessage id="m.Ambassadors_Applications_Private-Pool" />,
                        path: 'private-pool',
                    },
                    {
                        access: 'Ambassadors_Applications_Review-Records',
                        element: <PageAmbassadorsApplicationsReviewRecords />,
                        name: <FormattedMessage id="m.Ambassadors_Applications_Review-Records" />,
                        path: 'review-records',
                    },
                ],
                access: 'Ambassadors_Applications',
                name: <FormattedMessage id="m.Ambassadors_Applications" />,
                path: 'applications',
            },
            {
                children: [
                    {
                        access: 'Ambassadors_Ambassadors_Public-Pool',
                        element: <PageAmbassadorsAmbassadorsPublicPool />,
                        name: <FormattedMessage id="m.Ambassadors_Ambassadors_Public-Pool" />,
                        path: 'public-pool',
                    },
                    {
                        access: 'Ambassadors_Ambassadors_Ambassadors',
                        element: <PageAmbassadorsAmbassadorsAmbassadors />,
                        name: <FormattedMessage id="m.Ambassadors_Ambassadors_Ambassadors" />,
                        path: 'ambassadors',
                    },
                ],
                access: 'Ambassadors_Ambassadors',
                name: <FormattedMessage id="m.Ambassadors_Ambassadors" />,
                path: 'ambassadors',
            },
            {
                children: [
                    {
                        access: 'Ambassadors_Data_Ambassador-Data',
                        element: <PageAmbassadorsDataAmbassadorData />,
                        name: <FormattedMessage id="m.Ambassadors_Data_Ambassador-Data" />,
                        path: 'ambassador-data',
                    },
                ],
                access: 'Ambassadors_Data',
                name: <FormattedMessage id="m.Ambassadors_Data" />,
                path: 'data',
            },
            {
                children: [
                    {
                        access: 'Ambassadors_Configurations_Mkt-Member-Config',
                        element: <PageAmbassadorsConfigurationsMktMemberConfig />,
                        name: <FormattedMessage id="m.Ambassadors_Configurations_Mkt-Member-Config" />,
                        path: 'mkt-member-config',
                    },
                    {
                        access: 'Ambassadors_Configurations_Incentive-Seasons',
                        element: <PageAmbassadorsIncentiveSeasons />,
                        name: <FormattedMessage id="pm.Ambassadors_Configurations_Incentive-Seasons" />,
                        path: 'incentive-seasons',
                    },
                ],
                access: 'Ambassadors_Configurations',
                name: <FormattedMessage id="m.Ambassadors_Configurations" />,
                path: 'configurations',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Ambassadors" />,
        access: 'Ambassadors',
        path: 'ambassadors',
    },
    /* 激励计划 Incentives */
    {
        children: [
            {
                access: 'Incentives_Incentive-Seasons',
                element: <PageIncentiveSeasons />,
                name: <FormattedMessage id="m.Incentives_Incentive-Seasons" />,
                path: 'incentive-seasons',
            },
            {
                access: 'Incentives_Task-Config',
                element: <PageTaskConfig />,
                name: <FormattedMessage id="m.Incentives_Task-Config" />,
                path: 'task-config',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Incentives" />,
        path: 'incentives',
    },
    /* Debate Championship 辩论大赛 */
    {
        children: [
            {
                access: 'Debate_Championship-Registrant_Information',
                element: <PageDebateRegistrantInfomation />,
                name: <FormattedMessage id="pm.Debate_Championship-Registrant_Information" />,
                path: 'registrant-information',
            },
        ],
        access: 'Debate_Championship',
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="pm.Debate_Championship" />,
        path: 'debate-championship',
    },
    /* Clone*/
    {
        children: [
            {
                access: 'Clone_Preset-Questions',
                element: <PageClonePresetQuestions />,
                name: <FormattedMessage id="m.Clone_Preset-Questions" />,
                path: 'preset_questions',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Clone" />,
        path: 'clone',
    },
    /* 超级账号 Super Users */
    {
        children: [
            {
                access: 'Super-Users_Behavior',
                element: <PageSuperUsersBehavior />,
                name: <FormattedMessage id="m.Super-Users_Behavior" />,
                path: 'behavior',
            },
            {
                access: 'Super-Users_Benefit',
                element: <PageSuperUsersBenifit />,
                name: <FormattedMessage id="m.Super-Users_Benefit" />,
                path: 'benefit',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Super-Users" />,
        path: 'SuperUsers',
    },
    /* 创作者中心 Creator */
    {
        children: [
            {
                access: 'Creator-Center_Topic',
                element: <PageCreatorTopics />,
                name: <FormattedMessage id="m.Creator_Center-Topic" />,
                path: 'topic',
            },
            {
                access: 'Creator-Center_Creator-Hub',
                element: <PageCreatorAcademy />,
                name: <FormattedMessage id="m.Creator_Center-Creator_Hub" />,
                path: 'creatorHub',
            },
            {
                access: 'Creator-Center_Top-Posts',
                element: <PageCreatorPosts />,
                name: <FormattedMessage id="m.Creator_Center-Top_Posts" />,
                path: 'topPosts',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Creator_Center" />,
        access: 'Creator-Center',
        path: 'creator',
    },
    /* 客户端配置 Client Config */
    {
        children: [
            {
                access: 'Client-Config_Plaza-Config',
                element: <PageClientConfigPlazaConfig />,
                name: <FormattedMessage id="m.Client-Config_Plaza-Config" />,
                path: 'plaza-config',
            },
            {
                access: 'Client-Config_Super-Users',
                element: <PagePostAirdropRootAccount />,
                name: <FormattedMessage id="m.Client-Config_Airdrop-Whitelist" />,
                path: 'super-users',
            },
            {
                access: 'Client-Config_Domain',
                element: <PageClientDomain />,
                name: <FormattedMessage id="m.Client-Config_Domain" />,
                path: 'domain',
            },
            {
                access: 'Client-Config_App-Version',
                element: <PageClientAppVersion />,
                name: <FormattedMessage id="m.Client-Config_App-Version" />,
                path: 'app',
            },
            {
                access: 'Client-Config_Channel-Code',
                element: <PageClientChannelCode />,
                name: <FormattedMessage id="m.Client-Config_Channel-Code" />,
                path: 'channel-code',
            },
            {
                access: 'Client-Config_Live-Recommendations',
                element: <PageClientLive />,
                name: <FormattedMessage id="m.Client-Config_Live-Recommendations" />,
                path: 'live-recommendations',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Client-Config" />,
        access: 'Client-Config',
        path: 'client-config',
    },
    /* IM */
    {
        children: [
            {
                access: 'IM_Messages',
                element: <PageIMMessage />,
                name: <FormattedMessage id="m.IM_Messages" />,
                path: 'message',
            },
        ],
        icon: <CommentOutlined />,
        name: 'IM',
        access: 'IM',
        path: 'im',
    },
    /* Mobile Store 手机商城 */
    {
        children: [
            {
                access: 'Mobile-Store_Orders',
                element: <PageMobileStoreOrders />,
                name: <FormattedMessage id="pm.Mobile-Store_Orders" />,
                path: 'orders',
            },
            {
                access: 'Mobile-Store_Refunds',
                element: <PageMobileStoreRefunds />,
                name: <FormattedMessage id="pm.Mobile-Store_Refunds" />,
                path: 'refunds',
            },
            {
                access: 'Mobile-Store_Inventory',
                element: <PageMobileStoreInventory />,
                name: <FormattedMessage id="pm.Mobile-Store_Inventory" />,
                path: 'inventory',
            },
            {
                access: 'Mobile-Store_Order-Config',
                element: <PageMobileStoreOrderConfig />,
                name: <FormattedMessage id="pm.Mobile-Store_Order-Config" />,
                path: 'order-config',
            },
        ],
        icon: <ShopOutlined />,
        name: <FormattedMessage id="pm.Mobile-Store" />,
        access: 'Mobile-Store',
        path: 'mobile-store',
    },
    /* 交易市场 Marketplace */
    {
        children: [
            {
                children: [
                    {
                        access: 'Marketplace_NFT_NFT-Collection',
                        element: <PageMarketplaceCollections />,
                        name: <FormattedMessage id="m.Marketplace_NFT_NFT-Collection" />,
                        path: 'nft-collection',
                    },
                    {
                        access: 'Marketplace_NFT_NFT',
                        element: <PageMarketplaceNft />,
                        name: <FormattedMessage id="m.Marketplace_NFT_NFT" />,
                        path: 'nft',
                    },
                    {
                        access: 'Marketplace_NFT_NFT-Marketplace',
                        element: <PageMarketplaceNftMarketplace />,
                        name: <FormattedMessage id="m.Marketplace_NFT_NFT-Marketplace" />,
                        path: 'nft-marketplace',
                    },
                ],
                access: 'Marketplace_NFT',
                name: <FormattedMessage id="m.Marketplace_NFT" />,
                path: 'nft',
            },
            {
                children: [
                    {
                        access: 'Marketplace_Token_Token-Config',
                        element: <PageTokenConfig />,
                        name: <FormattedMessage id="m.Marketplace_Token_Token-Config" />,
                        path: 'token-config',
                    },
                    {
                        access: 'Marketplace_Token_Pool',
                        element: <PageTokenPool />,
                        name: <FormattedMessage id="m.Marketplace_Token_Pool" />,
                        path: 'token-pool',
                    },
                    {
                        access: 'Marketplace_Token_Top-Token',
                        element: <PageTokenTopToken />,
                        name: <FormattedMessage id="m.Marketplace_Token_Top-Token" />,
                        path: 'top-token',
                    },
                ],
                access: 'Marketplace_Token',
                name: <FormattedMessage id="m.Marketplace_Token" />,
                path: 'token',
            },
            {
                children: [
                    {
                        access: 'Marketplace_Meme_Memecoin',
                        element: <PageNFTMarketplaceMemecoin />,
                        name: 'Memecoin',
                        path: 'memecoin',
                    },
                ],
                access: 'Marketplace_Meme',
                name: 'Meme',
                path: 'launch-fun',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Marketplace" />,
        access: 'Marketplace',
        path: 'marketplace',
    },
    /* 空头管理 Airdrop */
    {
        children: [
            {
                access: 'Airdrop_Airdrop-Seasons',
                element: <PageOfficialAirdropList />,
                name: <FormattedMessage id="m.Airdrop_Airdrop-Seasons" />,
                path: 'airdrop-seasons',
            },
            {
                access: 'Airdrop_Airdrop-Config',
                element: <PageOfficialAirdropConfig />,
                name: <FormattedMessage id="m.Airdrop_Airdrop-Config" />,
                path: 'airdrop-config',
            },
        ],
        icon: <AreaChartOutlined />,
        name: <FormattedMessage id="m.Airdrop" />,
        access: 'Airdrop',
        path: 'airdrop',
    },
    /* 系统管理 System Management */
    {
        children: [
            {
                access: 'System-Management_Accounts',
                element: <PageAccountManagement />,
                name: <FormattedMessage id="m.System-Management_Accounts" />,
                path: 'accounts',
            },
            {
                access: 'System-Management_Roles',
                element: <PageRoleManagement />,
                name: <FormattedMessage id="m.System-Management_Roles" />,
                path: 'roles',
            },
            {
                access: 'System-Management_Logs',
                element: <PageLogs />,
                name: <FormattedMessage id="m.System-Management_Logs" />,
                path: 'logs',
            },
            {
                access: 'System-Management_Downloads',
                element: <PageExports />,
                name: <FormattedMessage id="m.System-Management_Exports" />,
                path: 'exports',
            },
        ],
        icon: <SettingOutlined />,
        name: <FormattedMessage id="m.System-Management" />,
        access: 'System-Management',
        path: 'system-management',
    },
] as MenuDataItem[];

export default routes;
