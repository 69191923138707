export default {
    p: {
        uu: {
            h: {
                status: '状态',
                baseInfo: '基本信息',
            },
            msg: {
                exportTips: 'Exporting, please check "System Management-Download Management" later',
            },
            columns: {
                uid: 'UID',
                userName: 'Username',
                nickname: 'Nickname',
                bio: 'Bio',
                parentUid: 'Parent UID',
                registrationTime: 'Registration Time',
                remark: 'Remark',
            },
            status: {
                permanent: 'Permanent',
                account: {
                    normal: 'Normal',
                    disabled: 'Disabled',
                    disabledAt: 'Disable Duration',
                    enabledAt: 'Enabled At',
                    freeze: 'Frozen',
                },
                muted: {
                    normal: 'Normal',
                    muted: 'Muted',
                    mutedAt: 'Mute Duration',
                    unMutedAt: 'Unmuted At',
                },
            },
            action: {
                mute: 'Mute',
                ban: 'Disable',
                muteTemporary: 'Temporary',
                mutePermanent: 'Permanent',
                banTemporary: 'Temporary',
                banPermanent: 'Permanent',
                unmute: 'Unmute',
                unban: 'Enable',
                avatar: 'Reset Avatar',
                name: 'Reset Username',
                nickname: 'Reset Nickname',
                bio: 'Reset Bio',
                statusfreeze: 'Frozen（永久）',
                freezePermanent: 'Permanent Freeze',
                freeze: 'Freeze Funds',
                unfreeze: 'Unfreeze Funds',
                liveDisable: 'Live Disabled',
                unliveDisable: 'Enable Live',
                liveDisableTemporary: 'Temporary',
                liveDisablePermanent: 'Permanent',
            },
            btn: {
                mangProfile: 'Manage Profile',
                mute: 'Mute',
                unmute: 'Unmute',
                disable: 'Disable',
                enable: 'Enable',
                freezeFunds: 'Freeze Funds',
                unfreezeFunds: 'Unfreeze Funds',
            },
            cycleType: {
                minute: 'Minutes',
                hour: 'Hours',
                day: 'Days',
            },
            message: {
                cycleNumber: 'Please enter a number between 1 and 999',
            },
            t: {
                c: {
                    school: 'School',
                    mobile: 'Mobile',
                    birthday: 'Birthday',
                },
            },
        },
    },
};
