import axios from './index';

/**
 * APIs
 */

// 获取有效大使数
export function gAmbIncentiveSeasonsCount(params: AmbIncentiveSeasonsParams) {
    return axios<ResType<number>>('/v1/ambassador/incentive/count', {
        method: 'get',
        params,
    });
}

// 获取大使激励计划列表
export function gAmbIncentiveSeasons(params: AmbIncentiveSeasonsParams) {
    return axios<PageResType<AmbIncentiveSeasonsResult>>('/v1/ambassador/incentive', {
        method: 'get',
        params,
    });
}

// 获取大使激励计划配置
export function gAmbIncentiveSeasonsConfig() {
    return axios<ResType<AmbIncentiveSeasonsConfigResult>>('/v1/ambassador/incentive/config', {
        method: 'get',
    });
}

// 保存大使激励计划配置
export function pAmbIncentiveSeasonsConfig(id: IdType, data: AmbIncentiveSeasonsConfigResult) {
    return axios<ResType<AmbIncentiveSeasonsConfigResult>>(`/v1/ambassador/incentive/config/${id}`, {
        method: 'put',
        data,
    });
}

// 新增大使激励计划
export function addAmbIncentiveSeasons(data: AddAmbIncentiveSeasonsParams) {
    return axios<ResType<AmbIncentiveSeasonsConfigResult>>(`/v1/ambassador/incentive`, {
        method: 'post',
        data,
    });
}

// 编辑大使激励计划
export function uAmbIncentiveSeasons(id: IdType, data: EditAmbIncentiveSeasonsParams) {
    return axios<ResType<AmbIncentiveSeasonsConfigResult>>(`/v1/ambassador/incentive/${id}`, {
        method: 'patch',
        data,
    });
}

// 删除大使激励计划
export function dAmbIncentiveSeasons(id: IdType) {
    return axios<ResType<AmbIncentiveSeasonsConfigResult>>(`/v1/ambassador/incentive/${id}`, {
        method: 'delete',
    });
}

/**
 * Types
 */
import { PageResType, ResType } from '@repo/typings/common';
import { IdType } from './ambassadors-configurations';

export type AmbIncentiveSeasonsParams = {
    limit?: number;
    order?: string;
    page?: number;
};

export type AmbIncentiveSeasonsResult = {
    id: string;
    activity_id: number;
    start_at: string;
    end_at: string;
    target: AmbIncentiveSeasonsTarget[];
    token_id: string;
    token_label: string;
    status: string;
    operator_account_id: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    operator_account: {
        name: string;
        status: number;
    };
};

export type AmbIncentiveSeasonsConfigLanguages = {
    title_lv1: string;
    title_lv2: string;
    description_lv1: string;
    description_lv2: string;
};

export type AmbIncentiveSeasonsConfigResult = {
    id?: string;
    languages: Record<string, AmbIncentiveSeasonsConfigLanguages>;
    points_lv1: string;
    points_lv2: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
};

export type AddAmbIncentiveSeasonsParams = {
    end_at: string;
    start_at: string;
    target: AmbIncentiveSeasonsTarget[];
    token_id: string;
    token_label?: string;
};

export type AmbIncentiveSeasonsTarget = {
    /**
     * 目标奖励
     */
    reward: string;
    /**
     * 目标大使数
     */
    user: number;
};

export type EditAmbIncentiveSeasonsParams = Omit<AddAmbIncentiveSeasonsParams, 'token_id'>;
