/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: 'Home',

        /**
         * Users
         *
         * 用户管理
         */
        Users: 'Users',

        Users_Users: 'Users',

        'Users_Identity-Verification-History': 'Identity Verification History',

        Users_Usernames: 'Usernames',

        'Users_Sanction-History': 'Sanction History',

        Users_Celebrities: 'Celebrities',

        /**
         * Content
         *
         * 内容管理
         */
        Content: 'Content',

        Content_Posts: 'Posts',

        'Content_Posts-Comments': 'Posts Comments',

        Content_Shorts: 'Shorts',

        'Content_Shorts-Comments': 'Shorts Comments',

        Content_Live: 'Live',

        /**
         * Creator
         *
         * 创作者中心
         */
        Creator_Center: 'Creator Center',

        'Creator_Center-Topic': 'Topic',

        'Creator_Center-Creator_Hub': 'Creator Hub',

        'Creator_Center-Top_Posts': 'Top Posts',

        /**
         * Ambassadors
         *
         * 大使计划
         */
        Ambassadors: 'Ambassadors',

        Ambassadors_Applications: 'Applications',
        'Ambassadors_Applications_Public-Pool': 'Public Pool',
        'Ambassadors_Applications_Private-Pool': 'Private Pool',
        'Ambassadors_Applications_Review-Records': 'Review Records',

        Ambassadors_Ambassadors: 'Ambassadors',
        'Ambassadors_Ambassadors_Public-Pool': 'Public Pool',
        Ambassadors_Ambassadors_Ambassadors: 'Ambassadors',

        Ambassadors_Data: 'Data',
        'Ambassadors_Data_Ambassador-Data': 'Ambassador Data',

        Ambassadors_Configurations: 'Configurations',
        'Ambassadors_Configurations_Private-Pool-Config': 'Private Pool Config',
        'Ambassadors_Configurations_Mkt-Member-Config': 'Mkt Member Config',

        /**
         * Incentives
         *
         * 激励计划
         */
        Incentives: 'Incentives',

        'Incentives_Incentive-Seasons': 'Incentive Seasons',

        'Incentives_Task-Config': 'Task Config',

        /**
         * Clone
         *
         */
        Clone: 'Clone',
        'Clone_Preset-Questions': 'Preset Questions',

        /**
         * Super Users
         *
         * 超级账号
         */
        'Super-Users': 'Super Users',

        'Super-Users_Behavior': 'Behavior',

        'Super-Users_Benefit': 'Benefit',

        /**
         * Reports
         *
         * 举报管理
         */
        Reports: 'Reports',

        'Reports_Report-Moderation': 'Report Moderation',

        'Reports_Moderation-Records': 'Moderation Records',

        /**
         * Client Config
         *
         * 客户端配置
         */
        'Client-Config': 'Client Config',

        'Client-Config_Plaza-Config': 'Plaza Config',

        'Client-Config_Super-Users': 'Super Users',

        'Client-Config_Airdrop-Whitelist': 'Airdrop Whitelist',

        'Client-Config_Domain': 'Domain',

        'Client-Config_App-Version': 'App Version',

        'Client-Config_Channel-Code': 'Channel Code',

        'Client-Config_Live-Recommendations': 'Live Recommendations',

        /**
         * IM
         */
        IM_Messages: 'Messages',

        /**
         * Airdrop
         *
         * 空投管理
         */
        'official-airdrop': 'Official Airdrop',
        oa: {
            'airdrop-events': 'Airdrop Events',
            'official-airdrop-configurations': 'Official Airdrop Configurations',
        },

        /**
         * Marketplace
         *
         * 交易市场
         */
        Marketplace: 'Marketplace',
        Marketplace_NFT: 'NFT',
        'Marketplace_NFT_NFT-Collection': 'NFT Collection',
        Marketplace_NFT_NFT: 'NFT',
        'Marketplace_NFT_NFT-Marketplace': 'NFT Marketplace',

        Marketplace_Token: 'Token',

        'Marketplace_Token_Token-Config': 'Token Config',

        Marketplace_Token_Pool: 'Pool',

        'Marketplace_Token_Top-Token': 'Top Token',
        /**
         * Airdrop
         *
         * 空投管理
         */
        Airdrop: 'Airdrop',

        'Airdrop_Airdrop-Seasons': 'Airdrop Seasons',

        'Airdrop_Airdrop-Config': 'Airdrop Config',

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': 'System Management',

        'System-Management_Accounts': 'Accounts',

        'System-Management_Roles': 'Roles',

        'System-Management_Logs': 'Logs',

        'System-Management_Exports': 'Downloads',
    },
};
