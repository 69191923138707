import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Typography } from 'antd';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */

/**
 * Components
 */

/**
 * Constants
 */

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import Permission from '@/components/permission';
import { gOrderManager, OrderManagerItem } from '@/services/mobile-store';
import ComponentConfigDrawer from './components/config-drawer';
import ComponentEditDrawer from './components/edit-drawer';

type State = {
    dataSource: OrderManagerItem[];
    entity: OrderManagerItem | null;
    userLimit: number;
    openConfigDrawer: boolean;
    openEditDrawer: boolean;
};

const PageMobileStoreOrderConfig: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openConfigDrawer: false,
        openEditDrawer: false,
        userLimit: 0,
    });

    /**
     * Requests
     */
    const {
        loading: loadingApi,
        refresh: refreshApi,
        run: runApi,
    } = useRequest(gOrderManager, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setState({
                dataSource: data.countryLimit,
                userLimit: data.userLimit,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<OrderManagerItem, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'country',
                title: <FormattedMessage id="ms.t.c.countryRegion" />,
                width: 200,
            },
            {
                dataIndex: 'sku',
                title: 'SKU',
                width: 200,
            },
            {
                dataIndex: 'specification',
                title: <FormattedMessage id="ms.t.c.specification" />,
            },
            {
                dataIndex: 'unitPrice',
                title: <FormattedMessage id="ms.t.c.unitPrice" />,
                width: 200,
            },

            {
                dataIndex: 'max',
                title: <FormattedMessage id="ms.t.c.purchaseLimit" />,
                tooltip: <FormattedMessage id="ms.f.orderLimitInfo" />,
                width: 200,
            },
            {
                dataIndex: 'operator',
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'operatorTime',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Mobile-Store_Order-Config_Edit" key="edit">
                        <Typography.Link onClick={() => setState({ openEditDrawer: true, entity })}>
                            <FormattedMessage id="b.edit" />
                        </Typography.Link>
                    </Permission>,
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingApi,
        options: {
            reload: refreshApi,
        },
        pagination: false,
        rowKey: (entity) => `${entity.country}_${entity.sku}_${entity.specification}`,
        scroll: {
            x: 1650,
            y: `calc(100vh - 240px)`,
        },
        search: false,
        toolBarRender: () => {
            return [
                <Permission permission="Mobile-Store_Order-Config_Configure" key="configure">
                    <Button onClick={() => setState({ openConfigDrawer: true })}>
                        <FormattedMessage id="b.config" />
                    </Button>
                </Permission>,
            ];
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runApi();
    }, []);

    return (
        <>
            <ProTable {...proTableProps} />

            <ComponentConfigDrawer
                defaultLimit={state.userLimit}
                open={state.openConfigDrawer}
                closeCallback={(reload) => {
                    setState({ openConfigDrawer: false });
                    if (reload) {
                        refreshApi();
                    }
                }}
            />

            <ComponentEditDrawer
                entity={state.entity}
                open={state.openEditDrawer}
                closeCallback={(reload) => {
                    setState({ openEditDrawer: false });
                    if (reload) {
                        refreshApi();
                    }
                }}
            />
        </>
    );
};

export default PageMobileStoreOrderConfig;
