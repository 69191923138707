export default {
    pq: {
        t: {
            c: {
                qid: 'Question ID',
                question: 'Question',
                answer: 'Answer',
            },
        },
    },
};
