import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Flex, Form, Input, PaginationProps, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import { RegistrantInformationParams } from '@/services/registrant-information';

/**
 * Components
 */
import SearchUser from '@/components/search-user';

/**
 * Constants
 */
const OrderStatusMap = {
    paid: <FormattedMessage id="ms.s.paid" />,
    refunded: <FormattedMessage id="ms.s.refunded" />,
};

/**
 * Types
 */
import type { ActionType, DragTableProps } from '@ant-design/pro-components';
import { FormProps } from 'antd/lib';
import { isEmpty, omitBy, trim } from 'lodash';
import dayjs from 'dayjs';
import { gRefunds, RefundsResult } from '@/services/mobile-store';

type State = {
    dataSource: RefundsResult[];
    entity: RefundsResult | null;
    openDrawer: boolean;
};

const PageMobileStoreRefunds: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl = useIntl();

    const tableRef = useRef<ActionType>();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        openDrawer: false,
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<RegistrantInformationParams>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Requests
     */
    const {
        loading: loadingApi,
        refresh: refreshApi,
        run: runApi,
    } = useRequest(gRefunds, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            setPagination({
                total: data.meta.total,
            });
            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const proTableProps: DragTableProps<RefundsResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="ms.t.c.refundId" />,
                width: 200,
            },
            {
                dataIndex: 'name',
                title: <FormattedMessage id="t.c.user" />,
                render: (_, entity: any) => {
                    const { name = '-', nick_name = '-' } = entity;

                    return (
                        <>
                            <div>{nick_name}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'order_id',
                title: <FormattedMessage id="ms.t.c.orderId" />,
                width: 200,
            },
            {
                dataIndex: 'actual_refund',
                title: <FormattedMessage id="ms.t.c.refundAmount" />,
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="ms.t.c.refundStatus" />,
                width: 200,
                valueEnum: OrderStatusMap,
            },
            {
                dataIndex: 'refund_time',
                title: <FormattedMessage id="ms.t.c.refundedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingApi,
        options: {
            reload: refreshApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1250,
            y: `calc(100vh - 352px)`,
        },
        search: false,
    };

    /**
     * FormProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            if (values.fe_date && values.fe_date.length === 2) {
                values.refund_time_start = values.fe_date[0].startOf('day').format();
                values.refund_time_end = values.fe_date[1].endOf('day').format();
            }
            values.fe_date = undefined;

            if (values.id) {
                values.id = trim(values.id);
            }

            if (values.order_id) {
                values.order_id = trim(values.order_id);
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    /**
     * Effects
     */
    useEffect(() => {
        runApi({
            page: pagination.current as any,
            limit: pagination.pageSize as any,
            ...omitBy(payload, isEmpty),
        });
    }, [payload, pagination.current, pagination.pageSize]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 'ms.t.c.refundId' })} allowClear />
                        </Form.Item>

                        <SearchUser form={formSearch} />

                        <Form.Item name="order_id" style={{ marginRight: 0 }}>
                            <Input placeholder={intl.formatMessage({ id: 'ms.t.c.orderId' })} allowClear />
                        </Form.Item>

                        <Form.Item
                            label={intl.formatMessage({ id: 'ms.t.c.refundedAt' })}
                            name="fe_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker
                                allowClear={true}
                                disabledDate={(current) => {
                                    // 禁止选择未来的日期
                                    return current && current > dayjs().endOf('day');
                                }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageMobileStoreRefunds;
